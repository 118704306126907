<template>

  <div class="console">
    
    <v-app id="vApp">

      <div id="alert-container">
        <div v-if="!allowNotifications">
          <v-list
              subheader
              two-line
              style="background-color: yellow;"
            >
            <v-list-tile>
              <v-list-tile-action>
                <v-switch v-model="allowNotifications" @change="theNotificationToggleChanged"></v-switch>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title><strong>Turn-on Notifications</strong></v-list-tile-title>
                <v-list-tile-sub-title>You'll have more success with heedi with notifications.</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
          
        </div>

        <div v-if="!notificationCheck1Error && notificationCheck2Error">
          <v-list
              subheader
              two-line
              style="background-color: yellow;"
            >
            <v-list-tile>
              <v-list-tile-action>
                &nbsp;
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title><strong>Allow Notifications in Your Browser</strong></v-list-tile-title>
                <v-list-tile-sub-title>Your setting for heedi.com equals: <span style="color: #888;font-weight: 700;">{{ permission }}</span>.</v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
          
        </div>

        
        
        <i id="lang-close" class="fas fa-times" v-on:click="closeNotificationAlert"></i>
      </div>


      

      
      <HeedarConsole :initialize="triggerInitialize" />


      <FeaturedRoutines />
      <br>
      <br>
      
      <!--
      <MevChains v-on:load-finished="showFooter"/>
      
      <VueFooter v-if="showFooterBool"/>
      -->
      <VueFooter />




      <!-- ACCEPT OR CHANGE DAYS OF WEEK -->
      <v-dialog v-model="showInitializationDialog" persistent max-width="700px">
        <v-card>
          <v-card-title>
            <div v-if="addedRoutineType === `exercise`">
              <div class="headline2">{{ addedRoutineTitle }}</div>
              <div>Select the days of week to perform exercise:</div>
            </div>
            <div v-else>
              <div class="headline2"><strong>{{ addedRoutineTitle }}</strong></div>
              <div>This action, along with a water routine, has been added to your routine.  Press Continue to go to your main Heedar view.</div>
            </div>
          </v-card-title>
          <v-card-text  v-if="addedRoutineType === `exercise`">
            <div class="formHolder">
              <table width="100%">
                <tr>
                  <td width="50%" class="column">
                    <v-checkbox
                      ref="DAYS_OF_WEEK_Monday"
                      v-bind:key="'DAYS_OF_WEEK_STEP_CONTENT - Monday'"
                      :label="'Monday'"
                      v-model="userDaysOfWeek['Monday']">
                    </v-checkbox>
                    <v-checkbox  
                      ref="DAYS_OF_WEEK_Tuesday"
                      v-bind:key="'DAYS_OF_WEEK_STEP_CONTENT - Tuesday'"
                      :label="'Tuesday'"
                      v-model="userDaysOfWeek['Tuesday']">
                    </v-checkbox>
                    <v-checkbox 
                      ref="DAYS_OF_WEEK_Wednesday" 
                      v-bind:key="'DAYS_OF_WEEK_STEP_CONTENT - Wednesday'"
                      :label="'Wednesday'"
                      v-model="userDaysOfWeek['Wednesday']">
                    </v-checkbox>
                    <v-checkbox
                      ref="DAYS_OF_WEEK_Thursday"  
                      v-bind:key="'DAYS_OF_WEEK_STEP_CONTENT - Thursday'"
                      :label="'Thursday'"
                      v-model="userDaysOfWeek['Thursday']">
                    </v-checkbox>
                  </td>
                  <td width="50%" class="column">
                    <v-checkbox
                      ref="DAYS_OF_WEEK_Friday"  
                      v-bind:key="'DAYS_OF_WEEK_STEP_CONTENT - Friday'"
                      :label="'Friday'"
                      v-model="userDaysOfWeek['Friday']">
                    </v-checkbox>
                    <v-checkbox
                      ref="DAYS_OF_WEEK_Saturday"  
                      v-bind:key="'DAYS_OF_WEEK_STEP_CONTENT - Saturday'"
                      :label="'Saturday'"
                      v-model="userDaysOfWeek['Saturday']">
                    </v-checkbox>
                    <v-checkbox  
                      ref="DAYS_OF_WEEK_Sunday"
                      v-bind:key="'DAYS_OF_WEEK_STEP_CONTENT - Sunday'"
                      :label="'Sunday'"
                      v-model="userDaysOfWeek['Sunday']">
                    </v-checkbox>
                  </td>
                </tr>
              </table>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="handleInitializationClick">Continue</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-bottom-sheet v-model="showWelcomeSheet" persistent >
        <div class="bottomSheetDiv">
          <!--
          <i id="panel-close" class="fas fa-times" v-on:click="showWelcomeSheet = !showWelcomeSheet;"></i>
          -->
          

        </div>
      </v-bottom-sheet>



      </v-app>
    </div>
</template>

<script>
  import '@/plugins/vuetify'
  import signinModule from '@/store/signin'
  import userModule from '@/store/user'
  //import minderModule from '@/store/minder'
  import settingsModule from '@/store/settings'
  import routineModule from '@/store/routine'
  //import experienceModule from '@/store/experience'
  //import statementModule from '@/store/statement'
  //import tallyModule from '@/store/tally'
  //import MevChains from '@/components/MevChains'
  //import MomentMaker from '@/components/MomentMaker'
  import VueFooter from '@/components/VueFooter'
  //import SixArticles from '@/components/SixArticles'
  import FeaturedRoutines from '@/components/FeaturedRoutines'
  //import TallyButtons from '@/components/TallyButtons'
  import HeedarConsole from '@/components/HeedarConsole'
  //import FourSquareMinderConsole from '@/components/console/FourSquareMinderConsole'
  //import CheckInCard from '@/components/console/CheckInCard'
  //import JournalCard from '@/components/console/JournalCard'
  import { mapActions, mapGetters} from 'vuex'
  import firebase from '@/firebase'

  //GSAP
    import { 
      //Back, 
      //CSSPlugin, 
      gsap, 
      //Power4, 
      //TweenLite, 
      //TweenMax, 
      //TimelineLite, 
      //TimelineMax 
      } from "gsap";
    

  export default {
    name: "Console",
    components: {
      //MomentMaker,
      //FourSquareMinderConsole,
      //MevChains,
      VueFooter,
      //SixArticles,
      FeaturedRoutines,
      //TallyButtons,
      //JournalCard,
      HeedarConsole,
    },
    data () {
      return {
        showConsoleBool: false,
        showFooterBool: false,
        showWelcomeSheet: false,
        terms: false,
        addedRoutineId: null,
        addedRoutineType: null,
        addedRoutineTitle: null,


        triggerInitialize: false,
        reloadInterval: null,


        showInitializationDialog: false,
        showNotificationAlert: false,
        notificationCheck1Error: false,
        notificationCheck2Error: false,
        notificationCheck3Error: false,

        userDaysOfWeek: {
              'Sunday': false,
              'Monday': true,
              'Tuesday': false,
              'Wednesday': false,
              'Thursday': true,
              'Friday': false,
              'Saturday': false,
            },

        userTimeOfDay: "afternoon",
        userSetCount: 1,


      }
    },
    computed: {
      /*
      ...mapGetters('experience', [
        'showJournalDashboardView',
        'showJournalSetupView',
        'experiencePhase'
      ]),
      ...mapGetters('minder', [
        'userMinders'
      ]),

      */
      ...mapGetters('settings', [
        'allowNotifications',
        'notificationSubscriptions'
      ]),

      
      ...mapGetters('auth', [
          'userAcceptedTerms',
          'editMode',
          'loggedIn',
          'profile'
      ]),
      browserInfo () {
        //localStorage.setItem('browserInfo', JSON.stringify({'name': browser.name, 'os': browser.os }))
        var info = JSON.parse(localStorage.getItem("browserInfo"))
        /*
        var userAgent = window.navigator.userAgent,
            platform = window.navigator.platform,
            macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms = ['iPhone', 'iPad', 'iPod'],
            os = null;

        if (macosPlatforms.indexOf(platform) !== -1) {
          os = 'Mac';
        } else if (iosPlatforms.indexOf(platform) !== -1) {
          os = 'iOS';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
          os = 'Windows';
        } else if (/Android/.test(userAgent)) {
          os = 'Android';
        } else if (!os && /Linux/.test(platform)) {
          os = 'Linux';
        }
        */

        var str = info.name+"-"+info.os
        return str

        //Possible return values:  chrome-Mac, chrome-iOS, chrome-Windows, chrome-Android, chrome-Linux
        //firefox-Mac, firefox-iOS, firefox-
      },
      permission () {
        var permission = Notification.permission 
        return permission
      },
      isValid () {
        if(this.notificationSubscriptions) {
          if(this.notificationSubscriptions[this.browserInfo]) {
            if(this.notificationSubscriptions[this.browserInfo].isValid) {
              return true
            }else{
              return false
            }
          }else{
            return false
          }
        }else{
          return false
        }
        
      },
      allowNotifications: {
        set: function (value) {
          this.$store.commit('settings/SET_ALLOW_NOTIFICATIONS', { allowNotifications: value }, { root: true })
        },
        get: function () {
          return this.$store.state.settings.allowNotifications
        }
    },
    },
    methods: {
      updateLayout () {
        this.$emit('close-mobile-menu', null)
      },
      ...mapActions('signin', [
        'autoSignIn',
        'anonymousLogin',
      ]),
      ...mapActions('routine', [
        'addRoutineToUser',
      ]),
      
      
      ...mapActions('search', [
        'setShowSearch',
        'setShowSearchIcon',
      ]),
      ...mapActions('settings', [
        'changeNotification',
        'setTermsOfUse',
        'askPermission',
        'subscribeUserToPush',
        'saveSubscription'
      ]),
      ...mapActions('auth', [
        'setEditMode',
        'setRole'
      ]),
      /*
      ...mapActions('tally', [
        'getUserTallies'
      ]), */
      theNotificationToggleChanged (value) {
        this.changeNotification(value)
        if(value) {this.notificationCheck1Error = false}
        

        //It might close, but there might be other problems with permissions
        if(!this.notificationCheck2Error) {
          this.closeNotificationAlert()
        }
        
      },
      closeJournalSetup () {
        this.setShowJournalSetupView(false)
      },
      
      overlayOn () {
        //console.log("yo yo")
        document.getElementById("overlay").style.display = "block";
      },
      overlayOff () {
        document.getElementById("overlay").style.display = "none";
      },
      showFooter () {
        this.showFooterBool = true;
      },
      checkTerms () {
        if(this.terms) {
          this.showWelcomeSheet = false;
          //this.$router.replace("/console")
          //store terms acceptance in db
          this.setTermsOfUse(true)


        }
      },
      async checkNotificationSetup () {

        //init the check vars
        this.notificationCheck1Error = false
        this.notificationCheck2Error = false
        this.notificationCheck3Error = false

        //Input vars:
        //this.allowNotifications
        if(!this.allowNotifications) {
          this.notificationCheck1Error = true
          this.openNotificationAlert(120)
        }

        if((this.permission === 'granted') && (!this.isValid)) {
          //do not pop the alert, but do reset the credentials
          //do not need to request permission
          this.notificationCheck3Error = true
          const subscriptionResult = await this.subscribeUserToPush()
          var subscription = JSON.parse(JSON.stringify(subscriptionResult))
          var data = {
            'endpoint': subscription.endpoint,
            'p256dh_key': subscription.keys.p256dh,
            'auth_key': subscription.keys.auth,
            'uid': this.profile.uid
          }
          // Update taxonomyStatus
          this.saveSubscription(data)
          this.notificationCheck3Error = false

        }else if((this.permission === 'denied') || (this.permission === 'default') || (!this.isValid)) {
          this.notificationCheck2Error = true
          
            //Problem could be permissions, or both credentials and permissions
            this.openNotificationAlert(120)
            //use browser info to determine which image to show
                //image1 - Android notification request
                //image2 - Desktop
                //image3 - iPhone

            //ask for permission
            const permission = await this.askPermission()
            //this.closeNotificationAlert()
            if(permission === 'granted') {
              const subscriptionResult = await this.subscribeUserToPush()
              var subscription2 = JSON.parse(JSON.stringify(subscriptionResult))
              var data2 = {
                'endpoint': subscription2.endpoint,
                'p256dh_key': subscription2.keys.p256dh,
                'auth_key': subscription2.keys.auth,
                'uid': this.profile.uid
              }
              // Update taxonomyStatus
              this.saveSubscription(data2)
              this.notificationCheck2Error = false
              
            }

        }
        
        


      },

      openNotificationAlert (height) {
        document.getElementById('alert-container').style.display='block';
        let anim = gsap.fromTo("#alert-container", {opacity: 0, height: 0}, {opacity: 1, height: height, duration: 3, ease: "elastic"})
        anim.play()
      },
      closeNotificationAlert () {
        let anim = gsap.fromTo("#alert-container", {opacity: 1, height: 150}, 
                        {
                          opacity: 0, 
                          height: 0, 
                          duration: 0.5,
                          onComplete: () => {
                            document.getElementById('alert-container').style.display='none';
                          }
                        })
        anim.play()
        
      },
      
      async handleInitializationClick () {
        if(this.editMode) {
          await this.addRoutineToUser({
            'id': this.addedRoutineId, 
            'routineType': this.addedRoutineType,
            'userDaysOfWeek': this.userDaysOfWeek,
            'userTimeOfDay': this.userTimeOfDay,
            'userSetCount': this.userSetCount
            })
          this.setEditMode(false)
          this.$router.replace('/console')
        }

        this.showInitializationDialog = false

      }
      
    },
    beforeCreate () {
      //MUST HAVE ALL THREE OF THESE MODULES LOADED FOR AUTOSIGNIN TO WORK
      if(!this.$store.state.signin) this.$store.registerModule('signin', signinModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
      if(!this.$store.state.routine) this.$store.registerModule('routine', routineModule)

    },
    async created () {
      /* eslint-disable no-undef */
      gtag('config', 'G-H9RD8W4MLR', {'page_path': '/console'});
      try {
        await this.autoSignIn()
      }catch(e) {
        /* eslint-disable no-console */
        console.log("error", e)
      }
      
      this.setShowSearchIcon(true)

      this.reloadInterval = setInterval(() =>{
          location.reload()
          clearInterval(this.reloadInterval)
          this.reloadInterval = null
      }, 8000000);

      
      
    },
    async mounted () {

      //new flow:  

      //check notifications 10 seconds later
      setTimeout(() => {
        this.checkNotificationSetup()
      }, 7000)

      //add a new command: 'initialize'




      var command = (this.$route.params.command) ? this.$route.params.command : null
      var type = (this.$route.params.type) ? this.$route.params.type : null
      var resourceId = (this.$route.params.resourceId) ? this.$route.params.resourceId : null

      if(resourceId) {
        resourceId = resourceId.replace(/-/g, ' ');   
        this.addedRoutineId = resourceId
      }
      if(type) {
        type = type.replace(/_/g, ' ')
        this.addedRoutineType = type
      }
      if(command) {
        switch(command) {
          case "initialize":

            
            
            if(this.editMode) {
              //show days of week confirmation dialog
              this.showInitializationDialog = true
              var unsubscribe7 = firebase.auth().onAuthStateChanged(async (user) => { 
                unsubscribe7()
                if(!user) {
                  await this.anonymousLogin()
                } 
              })

              this.addedRoutineTitle = this.$route.params.title
            }

            

            break;


          case "add":   //add an existing routine to the the user's routineTemplates sub-collection, resourceId could be a routineId

            

            //if(!this.userAcceptedTerms) {
            //  this.showWelcomeSheet = true
            //}
            if(this.editMode) {
              var unsubscribe8 = firebase.auth().onAuthStateChanged(async (user) => { 
                unsubscribe8()
                if(!user) {
                  await this.anonymousLogin()
                } 


                //add routine to user
                
                await this.addRoutineToUser({'id': resourceId, 'routineType': type})

              })
            }

            //Need this here, and another call within SearchRoutines to prevent user from refreshing page and duplication the addRoutineToUser fuction execution.
            this.setEditMode(false)

            break;

          default:
            break;
        }
      }



    },
    updated () {
      
    },
    watch: {

    }
  }
</script>

<style scoped>
#vApp {
 background-color: transparent;
}
#alert-container {
  display: none;
  text-align: center;
  /*background-color: #ffce54;*/
  background-color: rgb(236, 240, 22);
  position: relative;
  padding: 10px;
  height: 0;
  opacity: 0;
}
#lang-close {
  display: inline;
  position: absolute;
  right: 29px;
  top: 13px;
  color: #333333;
  font-size: 2em;
}
.formHolder {
  padding-right: 30px;
  padding-left: 30px;
}

.headline2 {
  font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 1.7em;
}

.column {
  vertical-align: top;
  padding: 10px;
}

.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  grid-gap: 5px;
  max-width: 1300px;
  margin: 0 auto 10px;
}

article {
  position: relative;
}
.article-img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.article-title {
  position: absolute;
  bottom: 1px;
  width: 100%;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.45);
}

.console {
  margin: 0 auto;  /* this centers the div on the page */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  max-width: 1300px;
}
.console::-webkit-scrollbar {
  display: none;
}


.consoleArticles {
  margin-bottom: 40px;
}

#overlay {
  position: fixed; /* Sit on top of the page content */
  display: none; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.8); /* Black background with opacity */
  z-index: 500; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

#overlayText{
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%,-50%);
  -ms-transform: translate(-50%,-50%);
}

.bottomSheetDiv {
  position: relative;
  color: white;
  background-color: black;
  height: 700px;
  text-align: center;
  overflow-y: scroll;
  overflow-x: hidden;
}
#panel-close {
  position: absolute;
  top: 25px;
  right: 25px;
  color: white;
  font-size: 2.2em;
}

.bottomSheetWelcomeMsg {
  color: white;
  display: inline-block;
  font-size: 1.8em;
  margin-bottom: 5px;
}

.thing {
  color: white;
  display: inline-block;
  margin-bottom: 20px;
  background-color: transparent;
  width: 300px;
}
.thing:last-child {
  margin-bottom: 0px;
}

.thingNumber {
  display: inline-block;
  background-color: red;
  padding: 4px;
  width: 30px;
  border-radius: 14px;
  vertical-align: top;
}
.theThing {
  display: inline-block;
  padding-left: 15px;
  text-align: left;
  width: 260px;
  background-color: transparent;
  
}
.thingTitle {
  font-weight: 600;
  font-size: 1.0em;
}
.thingsContainer {
  background-color: transparent;
  display: inline-block;
  margin: 0 auto;
}

.termsWrapper {
  background-color: transparent;
  margin: 0 auto;
  max-width: 360px;
}
.theTermsCB {
  background-color: transparent;
  padding-left: 60px;
  color: white;
}
@media screen and (min-width: 360px) {
  .thing:last-child {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 375px) {
  .theThing {
    padding-left: 15px;
    width: 305px;
  }
  .thingNumber {
    padding: 4px;
    width: 30px;
    border-radius: 14px;
  }
  .thing {
    margin-bottom: 40px;
    width: 335px
  }
  .thing:last-child {
    margin-bottom: 20px;
  }
  .thingTitle {
    font-weight: 600;
    font-size: 1em;
  }
  .bottomSheetDiv {
    padding: 5px;
    height: 700px;
  }
  .bottomSheetWelcomeMsg {
    margin-bottom: 25px;
  }
}
@media screen and (min-width: 375px) and (min-height: 800px) {
  .bottomSheetDiv {
    height: 750px;
  }
}

@media screen and (min-width: 410px) {
  .thing {
    width: 370px
  }
  .bottomSheetDiv {
    height: 650px;
  }
}
@media screen and (min-width: 410px) and (min-height: 800px) {
  .bottomSheetDiv {
    height: 750px;
  }
}

@media screen and (min-width: 500px) {
  .theThing {
    padding-left: 15px;
    width: 320px;
  }
  .thingNumber {
    padding: 4px;
    width: 30px;
    border-radius: 14px;
  }
  .thing {
    margin-bottom: 40px;
    width: 450px
  }
  .thing:last-child {
    margin-bottom: 40px;
  }
  .bottomSheetDiv {
    padding: 5px;
  }
  .thingTitle {
    font-weight: 600;
    font-size: 1em;
  }
  
}

@media screen and (min-width: 768px) {
  .bottomSheetDiv {
    height: 990px;
  }
}


@media screen and (min-width: 1000px) {
  .bottomSheetDiv {
    height: 1400px;
  }

}

</style>

<template>
  <div class="heedarConsole" :id="'heedarConsole'" ref="heedarConsole">


      <div class="heedarHeader">
        <div class="agendaTitle">
          <div class="agendaTitleDate">
            {{dayOfWeekName}}
          </div>
          <div class="actionWakeButton">
            <router-link to="/routine/search/">Add Routine</router-link>
          </div>
        </div>
      </div>

      <div class="demoContainer" id="demoContainer">

        <!--
        <div id="heedarContainer">
          <main>
            <section class="halfWidth"></section>
            <section class="halfWidth">
              <section class="halfHeight"></section>
              <section class="halfHeight"></section>
            </section>
          </main>
        </div>
        -->

        <div class="onDeckContainer">
          <div class="onDeckContainerGradient">
            &nbsp;
          </div>
          <div class="box" 
            v-for="(item, index) in sortedAgendaItems"
            v-bind:ref="'heedarAgendaItem'+item.agendaItemId"
            v-bind:id="'heedarAgenda'+item.agendaItemId"
            v-bind:key="item.agendaItemId" 
            @click.stop="showAgendaItemDetail({item: item, index: index})"
            >
            <div class="agendaItemTextWrapper" v-bind:ref="'heedarAgendaItemText'+item.agendaItemId">
              <div class="heedarItemTitle">
                {{ item.momentTitle }}
              </div>
              <div class="heedarItemSubtitle">
                {{ item.momentSubtitle }}
              </div>
              <div class="countdown notranslate"
                  translate="no"
                  :id="`timer2`+item.agendaItemId">
                  --
              </div>
            </div>

            <div class="iconBackgroundInItem" v-bind:ref="'iconImg'+item.agendaItemId">
              <img :src="item.routineIconImageBlack" alt="heedi" class="iconImg">
            </div>

            <div v-if="itemStatuses[index]">
              <div class="heedarItemImageContainer" v-if="(itemStatuses[index].newState == 'ready') || (itemStatuses[index].newState == 'expired')">
                <img :src="item.routineImage" class="heedarItemImage">
              </div>

              <!--
              <div
                v-bind:ref="'partnerIcon'"
                class="partnerIcon" 
                @click.stop="addAPartner(item)"
                v-if="(itemStatuses[index].newState == 'next1')">
                <img :src="require('@/assets/partnerIcon.png')" alt="heedi" class="partnerIconImg"  :title="`Add a partner for the '`+item.momentTitle+`' routine`">
              </div>
              -->
              

              <div 
                class="commentsIcon" 
                v-bind:ref="'commentsIcon'"
                @click.stop="showComments(item)"
                v-if="(itemStatuses[index].newState == 'next1') && showCommentsIcon">
                <div class="commentIconHolder">
                  <div class="starAlertHolder" v-if="showStarAlert">
                    <img :src="require('@/assets/smallStar_red.png')" class="starAlertImg" >
                  </div>
                  <i class="fas fa-comments commentsIconImg2" :title="`'`+item.momentTitle+`' comments`"></i>
                  <!--
                  <img :src="require('@/assets/commentsIcon.png')" alt="heedi" class="commentsIconImg" :title="`'`+item.momentTitle+`' comments`">
                  -->
                </div>
              </div>

              


            </div>


            <div 
              class="intentMeanCategory"
              v-if="itemStatuses[index] && ((itemStatuses[index].newState === 'next1') || (itemStatuses[index].newState === 'ready'))"
            
            >
              {{ item.intentMeanCategory }}
            </div>

            
            
            

          </div>
        </div>

        <div class="starBackground" ref="star">
          <img :src="require('@/assets/smallStar_grey_filled.png')" alt="heedi" class="starImg">
        </div>


        <!-- End of Day -->
        <div class="eodText end" ref="eodTextEnd">
          End
        </div>
        <div class="eodText of" ref="eodTextOf">
          of
        </div>
        <div class="eodText day" ref="eodTextDay">
          Day
        </div>


      </div>
      


      
      <div class="stepsHolder" v-if="(veryNextItem != null) && (itemStatuses[veryNextItemIndex])">
        <div v-if="itemStatuses[veryNextItemIndex].newState == 'ready'" ref="stepsBoard" class="stepsHolder2">
          <OutcomeForm 
            :agenda-item="veryNextItem"
            parent-view="heedar"
            v-on:expire-item="expireItem" />
        </div>
      </div>


      <div v-if="veryNextItem">
        <HeediInsighter :very-next-item='veryNextItem'/>
      </div>
      <div v-else>
        <br><br><br>
      </div>

      <div class="heediSections">
        <div class="sectionHolder" @click="dropDownDayPlanClicked">
          <div class="sectionTitle">
            Day Plan
          </div>
          <div class="sectionChevron">
            <i class="fas fa-chevron-down" v-if="!dropDownDayPlanActive"></i>
            <i class="fas fa-chevron-up" v-else></i>
          </div>
          <div class="sectionExpandedArea" v-show="dropDownDayPlanActive">
            <!-- DAY PLAN -->
              <div v-if="sortedAgendaItems != null">
                <div class="dayPlanArea" v-if="sortedAgendaItems.length > 0">
                  <div class="dayPlanContainer">
                    <!--
                    <div class="dayPlanHeaderBanner">
                      <div class="heediInsightLogo" v-if="showLogo">
                        <img :src="require('@/assets/heedi_homepage_logo_red_solo.png')" alt="heedi">
                      </div>
                      <div class="agendaTitle">
                        <div class="agendaTitleText">
                          Day Plan
                        </div>
                      </div>
                    </div>
                    -->
                    <div class="timelineContainer2" v-if="agendaItems != null">
                      <div class="timelineItem" 
                        v-for="(item, index) in sortedAgendaItems"
                        v-bind:key="item.agendaItemId" 
                        :id="`agendaItem`+item.agendaItemId"
                        @click.stop="showAgendaItemDetail({item: item, index: index})"
                        >
                        <div class="itemTitle" :id="`title`+item.agendaItemId">{{ item.momentTitle }}</div>
                        <div class="countdown countdown2 notranslate" translate="no" :id="`timer`+item.agendaItemId">--</div>
                        <div class="starmark" :id="`check`+item.agendaItemId" v-if="item.isDidItDone"><img :src="require('@/assets/smallStar_grey_filled.png')" alt="heedi" class="verySmallStarImg"></div>
                        <div class="checkmark" :id="`check`+item.agendaItemId" v-else-if="item.isDone"><i class="fas fa-check"></i></div>
                      </div>
                    </div>
                  <br />
                  </div>
                </div>
              </div>
          </div>
        </div>
        <div class="sectionHolder" @click="dropDownPacingClicked" v-if="actionWakeOutcomeReports">
          <div class="sectionTitle">
            Pacing
          </div>
          <div class="sectionChevron">
            <i class="fas fa-chevron-down" v-if="!dropDownPacingActive"></i>
            <i class="fas fa-chevron-up" v-else></i>
          </div>
          <div class="sectionExpandedArea" v-if="dropDownPacingActive">
            
            <pacing-data />
            
            <br><br>
          </div>
        </div>
        <div class="sectionHolder" @click="dropDownRoutinesClicked">
          <div class="sectionTitle">
            Active Routines
          </div>
          <div class="sectionChevron">
            <i class="fas fa-chevron-down" v-if="!dropDownRoutinesActive"></i>
            <i class="fas fa-chevron-up" v-else></i>
          </div>
          <div class="sectionExpandedArea" v-show="dropDownRoutinesActive">
            <div class="activeRoutines">
              <ActiveRoutines 
                v-on:clear="clearConsole" 
                v-on:initialize-console="initializeConsole" />
            </div>
          </div>
          <br>
        </div>

      </div>

      <!--
      <div class="activeRoutines">
        <ActiveRoutines 
          v-on:clear="clearConsole" 
          v-on:initialize-console="initializeConsole" />
      </div>
      -->


      <!-- DAY PLAN 
      <div v-if="sortedAgendaItems != null">
        <div class="dayPlanArea" v-if="sortedAgendaItems.length > 0">
          <div class="dayPlanContainer">

            <div class="dayPlanHeaderBanner">
              <div class="heediInsightLogo" v-if="showLogo">
                <img :src="require('@/assets/heedi_homepage_logo_red_solo.png')" alt="heedi">
              </div>
              <div class="agendaTitle">
                <div class="agendaTitleText">
                  Day Plan
                </div>
              </div>
            </div>

            <div class="timelineContainer2" v-if="agendaItems != null">
              <div class="timelineItem" 
                v-for="(item, index) in sortedAgendaItems"
                v-bind:key="item.agendaItemId" 
                :id="`agendaItem`+item.agendaItemId"
                @click.stop="showAgendaItemDetail({item: item, index: index})"
                >
                <div class="itemTitle" :id="`title`+item.agendaItemId">{{ item.momentTitle }}</div>
                <div class="countdown countdown2 notranslate" translate="no" :id="`timer`+item.agendaItemId">--</div>
                <div class="starmark" :id="`check`+item.agendaItemId" v-if="item.isDidItDone"><img :src="require('@/assets/smallStar_grey_filled.png')" alt="heedi" class="verySmallStarImg"></div>
                <div class="checkmark" :id="`check`+item.agendaItemId" v-else-if="item.isDone"><i class="fas fa-check"></i></div>
              </div>
            </div>
          <br />
          </div>
        </div>
      </div>
      -->

      <!--
      <AddPartner />
      -->

      <!-- BOTTOM SHEET -->
      <v-bottom-sheet v-model="agendaDetailSheet">
          <div class="bottomSheetContainer">
            
              <i id="panel-close" class="fas fa-times" v-on:click="agendaDetailSheet = !agendaDetailSheet;selectedAgendaItem=null;selectedAgendaItemIndex=null;"></i>
              <br><br>
              <div v-if="selectedAgendaItem" class="momentTitle">
                
                {{ selectedAgendaItem.intentMeanCategory }}
                &nbsp;-&nbsp;
                {{ selectedAgendaItem.momentSubtitle.substring(0,22) }}
              </div>
              <br />
              <!-- SHOW OUTCOME FORM -->
              <div class="outcomeFormHolder" v-if="selectedAgendaItem">
                
                  <OutcomeForm
                    
                    :parent-view="`bottomSheet`"
                    :agenda-item="selectedAgendaItem"
                    v-on:expire-item="expireItem" />
                
              </div>
              
              <!--
              <div v-if="selectedAgendaItem">
                  <div class="picIconHolder"  v-if="selectedAgendaItem.routineImage == null">
                      <div class="image-upload">
                          <label @click="$refs.inputUpload1.click()" id="label1">
                            <div>
                              <i class="fas fa-camera"></i>
                            </div>
                          </label>
                          <input v-show="false" id="file-input1" ref="inputUpload1" type="file" @change="uploadImg" />
                      </div>
                  </div>
                  
                  <div v-else style="text-align:center;">
                      <img :src="selectedAgendaItem.routineImage" class="item-img">
                  </div>
                  
              </div>
              -->
            
          </div>
      </v-bottom-sheet>


      <!-- Comments Dialog -->
      <v-dialog v-model="showCommentsDialog" max-width="700px">
        <v-card>
          <v-card-title>
            <div v-if="selectedAgendaItem">
              <div style="display: inline-block;">
                <img :src="selectedAgendaItem.routineIconImageGrey" height="70">
              </div>
              <span class="headline">{{ selectedAgendaItem.momentTitle }} comments</span>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="routineCommentsHolder" v-if="sortedRoutineComments.length > 0">
              <ul>
                <li v-for="(comment, index) in sortedRoutineComments" :key="`comment` + index">


                  <div class="commentBoxHolder">
                    <div class="commentDateHolder">
                      {{commentDates[index]}}
                    </div>
                    
                      {{ comment.comment }}
                    
                  </div>
                </li>
              </ul>
              <br><br>
            </div>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click="showCommentsDialog = false; selectedAgendaItem=null;">Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


  </div>
</template>

<script>
  //Some example imports you could have:
    //import Worker from "worker-loader!@/worker";
    
    import firebase from '@/firebase'
    import db from '@/db'
    import experienceModule from '@/store/experience'
    import HeediInsighter from '@/components/HeediInsighter'
    import PacingData from '@/components/PacingData'
    //import AddPartner from '@/components/AddPartner'
    import ActiveRoutines from '@/components/ActiveRoutines'
    import OutcomeForm from '@/components/OutcomeForm'
    import outcomeModule from '@/store/outcome'
    import settingsModule from '@/store/settings'
    import { mapGetters, mapActions } from 'vuex'
    import routineModule from '@/store/routine'
    import notificationModule from '@/store/notification'
    import moment from 'moment-timezone'


    //GSAP
    import { 
      Back, 
      //CSSPlugin, 
      gsap, 
      //Power4, 
      //TweenLite, 
      //TweenMax, 
      TimelineLite, 
      //TimelineMax 
      } from "gsap";
    import { ExpoScaleEase, RoughEase, SlowMo } from "gsap/EasePack";
    import { Draggable } from "gsap/Draggable";
    import { MotionPathPlugin } from "gsap/MotionPathPlugin";
    //import { PixiPlugin } from "gsap/PixiPlugin.js"
    gsap.registerPlugin(Draggable, MotionPathPlugin, ExpoScaleEase, RoughEase, SlowMo);


    

  export default {
    name: "HeedarConsole",
    components: {
      HeediInsighter,
      ActiveRoutines,
      OutcomeForm,
      PacingData,
      //AddPartner,
    },
    props: {
      
      overlayType: {
        type: String
      },
      
      parentView: {
        type: String
      },


      initialize: {
        type: Boolean
      }
    },
    data () {
      return {
        showStarAlert: false,
        showCommentsIcon: false,
        showLogo: false,
        showOutcomeForm: false,

        // countdownTimers: [],
        animationTimer: null,
        oldStatusHolder: [],
        
        expiredItems: [],
        expiredItemsTimer: null,
        // heedarResetTimer: null,
        // newDayTimer: null,
        itemStatuses: [],
        itemStatusLogs: [],

        // itemNotificationStatuses: [],

        timeline: null,
        timeline2: null,

        readyItemIndex: null,
        readyItem: null,


        savedWindowSize: null,
        savedDayStamp: null,

        agendaDetailSheet: false,
        selectedAgendaItem: null,
        selectedAgendaItemIndex: null,


        didItToggle: false,
        imageData: null,
        compressedImageData: null,

        outcomeFormInputs: [],
        textRules: [
          (v) => !!v || 'required',
          (v) => (v && v.length <= 100) || 'Max 100 characters'
        ],

        selectRules: [
          (v) => !!v || 'required',
        ],


        heedarInitialized: false,
        dailyAgendaReport: null,


        showRoutineDetailsSnackbar: false,


        interval: {
          // to keep a reference to all the intervals
          intervals : new Set(),
          
          // create another interval
          make(...args) {
              var newInterval = setInterval(...args);
              this.intervals.add(newInterval);
              return newInterval;
          },

          // clear a single interval
          clear(id) {
              this.intervals.delete(id);
              return clearInterval(id);
          },

          // clear all intervals
          clearAll() {
              for (var id of this.intervals) {
                  this.clear(id);
              }
          }
        },


        showCommentsDialog: false,


        //dropdown control variables
        dropDownDayPlanActive: false,
        dropDownPacingActive: false,
        dropDownRoutinesActive: false,
        

      }
    },
    computed: {
      ...mapGetters('routine', [
        'agendaItems',
        'routineComments'
        
      ]),
      ...mapGetters('auth', [
        'profile',
        'loggedIn',
        'commentsLastViewedDate'
      ]),
      ...mapGetters('outcome', [
        'userOutcomes',
        'outcomesToday',
        'outcomesYesterday',
        'outcomesLastTime',
        'outcomesLastWeek',
        'actionWakeHappinessScores',
        'actionWakeOutcomeReports',
        'actionWakeWorkSessionItems'
      ]),
      ...mapGetters('settings', [
        'allowNotifications',
        'notificationSubscriptions'
      ]),


      commentDates () {
        if(this.selectedAgendaItem && this.sortedRoutineComments.length > 0) {
          let commentDatesArray = []
          this.sortedRoutineComments.forEach(comment => {
            
            commentDatesArray.push(moment(comment.dateCreated.toDate()).format("MM") + "/"
            + moment(comment.dateCreated.toDate()).format("DD") + " "
            + moment(comment.dateCreated.toDate()).format("h") + ":"
            + moment(comment.dateCreated.toDate()).format("mm") + " "
            + moment(comment.dateCreated.toDate()).format("a"))
          })

          return commentDatesArray
        } else {
          return []
        }
      },

      remainingItems () {
        if(this.agendaItems != null) {
          var items = []
          //need to convert the agendaItems object into an array
          const keys = Object.keys(this.agendaItems)
          keys.forEach(key => {
            items.push(this.agendaItems[key])
          })
          var remainItems = items.filter(item => {
            if (!this.expiredItems.includes(item.agendaItemId)){
              return item
            }
          })
          return remainItems
        } else {
          return []
        }
      },
      
      veryNextItem () {
        if(this.sortedAgendaItems != null) {
          var now = moment().valueOf()

          /* eslint-disable no-unused-vars */
          //var len = this.expiredItems.length   //IMPORTANT:  Without this line, the veryNextItem does not get called when ready items expire.  'next2' & 'next3' items do not advance
          
          //var veryNextItem = this.sortedAgendaItems.find(item => !item.isDone && ((item.startTime + 180000) > now))

          var theItems = this.remainingItems
          theItems.sort((a, b) => parseFloat(a.startTime) - parseFloat(b.startTime));
          var veryNextItem = theItems.find(item => !item.isDone && ((item.startTime + 180000) > now))
          
          if(veryNextItem) {
            return veryNextItem
          }else{
            //this is probably where I introduce the end state and caboose concept
            //console.log("no more items")
            
            return 0
          }

        } else {
          return null
        }
      },
      
      veryNextItemIndex () {
        if(this.veryNextItem != null) {
          //var now = moment().valueOf()
          var index =  this.sortedAgendaItems.findIndex((item) => item.agendaItemId == this.veryNextItem.agendaItemId)
          return index

        } else {
          return null
        }
      },

      dayOfWeekName () {
        return moment().format('dddd') + `, `+ moment().format('Y')+ `-`+moment().format('MM')+`-`+moment().format('DD')
      },

      todayDayStamp () {
        var now = moment()
        var dayStamp = now.format("YYYY") + "-" + now.format("MM") + "-" + now.format("DD")
        return dayStamp
      },

      sortedAgendaItems () {
        if(this.agendaItems != null) {
          var agendaItemsHolder = []
          for(const key in this.agendaItems) {
            agendaItemsHolder.push(this.agendaItems[key])      //got to convert object into an array
          }
          return agendaItemsHolder.sort((a, b) => parseFloat(a.startTime) - parseFloat(b.startTime));
        }else{
          return null
        }
      },

      sortedRoutineComments () {
        if(this.routineComments.length > 0) {
          var clone = []    // Creating a clone because the sort function mutates routineComments outside mutation handlers
          this.routineComments.forEach(comment => {
            clone.push(comment)
          })
          return clone.sort((a,b) => b.dateCreatedStamp - a.dateCreatedStamp)
        }else {
          return []
        }
      },

      browserInfo () {
        //localStorage.setItem('browserInfo', JSON.stringify({'name': browser.name, 'os': browser.os }))
        var info = JSON.parse(localStorage.getItem("browserInfo"))
        var str = info.name+"-"+info.os
        return str
      },
      permission () {
        var permission = Notification.permission 
        return permission
      },
      isValid () {
        if(this.notificationSubscriptions) {
          if(this.notificationSubscriptions[this.browserInfo]) {
            if(this.notificationSubscriptions[this.browserInfo].isValid) {
              return true
            }else{
              return false
            }
          }else{
            return false
          }
        }else{
          return false
        }
        
      },







    },
    watch: {
      veryNextItem: {
        deep: true,
        immediate: true,
        handler: async function (newVal, oldVal) {
          

          if((newVal === 0) || (oldVal === 0)) {
            this.showEod()
            return
          }
          if(this.sortedRoutineComments) {
            if((newVal === oldVal)) {    //IMPORTANT: The 'newVal === oldVal' part reduces the number of calls to getComments() from 3 to 2
              
              await this.getComments(newVal.routineId)
              this.showCommentsIcon = false
              if(this.sortedRoutineComments.length > 0) {
                this.showCommentsIcon = true
                if(this.commentsLastViewedDate && this.commentsLastViewedDate[newVal.routineId]) {
                  if(
                    (this.sortedRoutineComments[0].dateCreatedStamp > moment(this.commentsLastViewedDate[newVal.routineId].newStamp.toDate()).valueOf())
                    &&
                    (this.sortedRoutineComments[0].userUid != this.profile.uid)
                    ) {
                    this.showStarAlert = true
                  }else{
                    this.showStarAlert = false
                  }
                }
              }else{
                //hide comments icon
                this.showCommentsIcon = false
              }
            }
          }else{
            this.showCommentsIcon = false
          }
        }
      },
      
      sortedAgendaItems: {
        deep: true,
        handler: async function (newVal, oldVal) {
          if(newVal && oldVal) {
            if((newVal.length != oldVal.length)) {    //glitches out if this is removed

              this.initializeConsole()
              
            }
          }
        }
      },

    },
    methods: {
      ...mapActions('routine', [
        'getDailyAgendaReport',
        'getDailyAgendaReport2',
        'resetAgendaItems',
        'getComments',
        'setCommentsLastViewedDate'
      ]),
      ...mapActions('notification', [
        'sendHeedarNotification',
      ]),
      ...mapActions('settings', [
        'refreshNotifications'
      ]),
      ...mapActions('outcome', [
        'getActionWakeHappinessData',
        'getActionWakeOutcomeData',
        'getActionWakeWorkSessionData'
      ]),
      

      showEod () {
        this.timeline2 = new TimelineLite()
        this.timeline2.to(this.$refs['eodTextEnd'], 1, {
          y: 95,
          opacity: 1,
        })
        this.timeline2.to(this.$refs['eodTextOf'], .9, {
          y: 108,
          opacity: 1,
        },
        '-=0.7'
        )
        this.timeline2.to(this.$refs['eodTextDay'], 1, {
          y: 95,
          opacity: 1,
        },
        '-=0.7'
        )
      },


      async changeDidItToggle (value) {
        var obj = {
            'bool': value,
            'item': this.selectedAgendaItem
        }
        await this.changeDidIt(obj)
      },


      

      async expireItem(payload) {
        this.rotateStar()
        //var item = payload.item
        var itemIndex = this.sortedAgendaItems.findIndex((item) => item.agendaItemId == payload.item.agendaItemId)
        //if(!this.expiredItems.includes(payload.item.agendaItemId)) { this.expiredItems.push(payload.item.agendaItemId) }       //Recently uncommented this line in hopes that the console heedar switches faster after "Did It"

        this.agendaDetailSheet = false
        this.advanceItemState({index: itemIndex, newState: "expired"})
        this.selectedAgendaItem = null
        this.selectedAgendaItemIndex = null

      },


      addAPartner() {
        // console.log("addAPartner")
      },

      async showComments(item) {
        this.selectedAgendaItem = item
        //pull comments
        this.showCommentsDialog = true
        this.showStarAlert = false
        this.setCommentsLastViewedDate({routineId: item.routineId})
        
      },

      closeComments () {
        this.showCommentsDialog = false
      },


      advanceItemState(payload) {
        var statusObj = {
          'newState': payload.newState,
          'oldState': this.itemStatuses[payload.index].newState,
          'stateLog': this.itemStatuses[payload.index].stateLog
        }

        this.$set(
          this.itemStatuses,
          payload.index,
          statusObj)


        //this.itemStatuses.splice(payload.index, 1, statusObj)
      },

      
      addRoutine(){
        this.$router.push('/routine')
      },


      showAgendaItemDetail (payload) {
        if(!payload.item.isDone || payload.item.routineType == "work sessions") { 
          this.selectedAgendaItem = payload.item
          this.selectedAgendaItemIndex = payload.index
          this.$nextTick(() => {
            //Until I improve the post-outcome look of bottom sheet, I'm going to to show
            this.agendaDetailSheet = true
          });
        }
      },
      

      overlayOff () {
        this.$emit('overlay-off')
      },


      playSound (sound) {
        if(sound) {
          var audio = new Audio(sound);
          audio.play();
        }
      },

      async initializeStatuses2 () {
        return new Promise((resolve) => {
          if(this.agendaItems != null) {
            const keys = Object.keys(this.agendaItems)
            var cloneArray = []
            keys.forEach((key,index) => {
              var stateLog = []
              stateLog.push('init')
              stateLog.push('start')
              var obj = {           
                'newState': 'start',
                'stateLog': stateLog,
              }
              cloneArray.splice(index,1,obj)
              
            })
            this.itemStatuses = cloneArray
            resolve()
          }
        })
      },

      async initializeAnimationRunner2 () {
        
        this.timeline = gsap.timeline()
        //this.timeline = new TimelineLite()
        //const { heedarAgendaItem, stepsBoard } = this.$refs
        //determine positions here
          var next1X,next1Y,next1Width,next1Height
          var next2X,next2Y,next2Width,next2Height
          var next3X,next3Y,next3Width,next3Height

          var ready1X
          var ready1Y
          var ready1Width
          var ready1Height
          //var ready1ScaleX
          //var ready1ScaleY
          //var next1ScaleX
          //var next1ScaleY
          //var next2ScaleX
          //var next2ScaleY
          //var next3ScaleX
          //var next3ScaleY

          var itemTextScaleX
          var itemTextScaleY
          var nextItemTextScaleX
          var nextItemTextScaleY

          if(window.innerWidth <= 280) {
            itemTextScaleX = 2.4
            itemTextScaleY = 2.4
            nextItemTextScaleX = 0.9
            nextItemTextScaleY = 0.9
          } else if(window.innerWidth <= 360) {
            itemTextScaleX = 2.5
            itemTextScaleY = 2.5
            nextItemTextScaleX = .95
            nextItemTextScaleY = .95
          } else if(window.innerWidth <= 560) {
            itemTextScaleX = 2.7
            itemTextScaleY = 2.7
            nextItemTextScaleX = 1.2
            nextItemTextScaleY = 1.2
          } else if(window.innerWidth <= 720) {
            itemTextScaleX = 3.3
            itemTextScaleY = 3.3
            nextItemTextScaleX = 1.55
            nextItemTextScaleY = 1.55
          } else {
            itemTextScaleX = 3.7
            itemTextScaleY = 3.7
            nextItemTextScaleX = 1.75
            nextItemTextScaleY = 1.75
          }
          

        
        this.interval.make(async () => {
        //this.animationTimer = setInterval(async () => {

          //There are seven states on the Heedar:  Init -> Start -> Next3 -> Next2 -> Next1 -> Ready1/Ready2 -> Expired

          if(document.getElementById('demoContainer')) {
            //Get the coordinate x,y points of the major spots on the heedar:  ready1, next1, next2, next3
              ready1X = - (document.getElementById('demoContainer').offsetWidth + 10)
              ready1Y = -38
              ready1Width = document.getElementById('demoContainer').offsetWidth + 9
              ready1Height = document.getElementById('demoContainer').offsetHeight

              next1X = - ((document.getElementById('demoContainer').offsetWidth) + 10)
              next1Y = -38
              next1Width = (document.getElementById('demoContainer').offsetWidth) * .7
              next1Height = (document.getElementById('demoContainer').offsetHeight)

              next2X = - (((document.getElementById('demoContainer').offsetWidth) * .3) + 10)
              next2Y = -38
              next2Width = (document.getElementById('demoContainer').offsetWidth) * .3
              next2Height = (document.getElementById('demoContainer').offsetHeight) * .5

              next3X = next2X
              next3Y = 102
              next3Width = (document.getElementById('demoContainer').offsetWidth) * .3
              next3Height = (document.getElementById('demoContainer').offsetHeight) * .5


            //hide next2 & next3 if an item is ready
              var readyIndex = this.itemStatuses.findIndex(status => status.newState == "ready")
              var next1Index = this.itemStatuses.findIndex(status => status.newState == "next1")
              var next2Index = this.itemStatuses.findIndex(status => status.newState == "next2")
              var next3Index = this.itemStatuses.findIndex(status => status.newState == "next3")
              //var next2Item = this.sortedAgendaItems[next2Index]
              //var next3Item = this.sortedAgendaItems[next3Index]

            if(readyIndex != -1) {
              if(next2Index && this.sortedAgendaItems[next2Index] && this.$refs['heedarAgendaItem'+this.sortedAgendaItems[next2Index].agendaItemId]) {
                this.timeline.to(this.$refs['heedarAgendaItem'+this.sortedAgendaItems[next2Index].agendaItemId], 0, {opacity: 0,ease: Back.easeInOut,})
              }
              if(next3Index  && this.sortedAgendaItems[next3Index] && this.$refs['heedarAgendaItem'+this.sortedAgendaItems[next3Index].agendaItemId]) {
                this.timeline.to(this.$refs['heedarAgendaItem'+this.sortedAgendaItems[next3Index].agendaItemId], 0, {opacity: 0,ease: Back.easeInOut,})
              }
            }else {
              //there are no ready items, so show the next2 and next3
              if(next1Index && this.sortedAgendaItems[next1Index] && this.$refs['heedarAgendaItem'+this.sortedAgendaItems[next1Index].agendaItemId]) {
                this.timeline.to(this.$refs['heedarAgendaItem'+this.sortedAgendaItems[next1Index].agendaItemId], 0, {opacity: 100,})
              }
              if(next2Index && this.sortedAgendaItems[next2Index] && this.$refs['heedarAgendaItem'+this.sortedAgendaItems[next2Index].agendaItemId]) {
                this.timeline.to(this.$refs['heedarAgendaItem'+this.sortedAgendaItems[next2Index].agendaItemId], 0, {opacity: 100,})
              }
              if(next3Index  && this.sortedAgendaItems[next3Index] && this.$refs['heedarAgendaItem'+this.sortedAgendaItems[next3Index].agendaItemId]) {
                this.timeline.to(this.$refs['heedarAgendaItem'+this.sortedAgendaItems[next3Index].agendaItemId], 0, {opacity: 100,})
              }
            }

            // console.log(this.itemStatuses)
            this.itemStatuses.forEach((statusObj, index) => {
              //var keys = Object.keys(this.agendaItems)
              var item = this.sortedAgendaItems[index]

              if((statusObj.newState == "ready") && (!statusObj.stateLog.includes("ready"))) {

                this.playSound('https://soundbible.com/mp3/spin_jump-Brandino480-2020916281.mp3')

                if(statusObj.oldState == "start") {
                  this.timeline.to(this.$refs['heedarAgendaItem'+item.agendaItemId], 1, {
                      width: ready1Width,
                      height: ready1Height,
                      x: ready1X,
                      y: ready1Y,
                      z: 100,
                      ease: Back.easeInOut,
                    })
                  this.timeline.to(this.$refs['heedarAgendaItemText'+item.agendaItemId], 0.5, {
                      scaleX: itemTextScaleX,
                      scaleY: itemTextScaleY,
                      y: 105,
                    },
                    '-=0.7' // Run the animation 0.5s early
                    )
                }else if(statusObj.oldState == "next1") {
                  /*
                  this.timeline.to(this.$refs['heedarAgendaItem'+item.agendaItemId], 1, {
                      width: ready1Width,
                      height: ready1Height,
                      x: ready1X,
                      y: ready1Y,
                      ease: Back.easeInOut, 
                    })
                  */
                  this.timeline.to(this.$refs['heedarAgendaItem'+item.agendaItemId], 1, {
                      width: ready1Width,
                      z: 100,
                      ease: Back.easeInOut, // Specify an ease
                      
                    },'-=1.0')
                  this.timeline.to(this.$refs['heedarAgendaItemText'+item.agendaItemId], 0.5, {
                      scaleX: itemTextScaleX,
                      scaleY: itemTextScaleY,
                      y: 105,
                    },
                    '-=0.7' // Run the animation 0.5s early
                    )
                }
                this.timeline.to(this.$refs['heedarAgendaItem'+item.agendaItemId], 0.5, {
                    
                    scaleX: 1.1,
                    scaleY: 1.1,
                    color: 'black',
                  },
                  '-=0.5' // Run the animation 0.5s early
                  )
                this.timeline.to(this.$refs['iconImg'+item.agendaItemId], 1.5, {
                  scaleX: 3,
                  scaleY: 3,
                },
                '-=1.0' // Run the animation 0.5s early
                )

                this.itemStatuses[index].stateLog.push("ready")

              }else if((statusObj.newState == "next1") && (!statusObj.stateLog.includes("next1"))) {



                if(next2Index == -1) {
                  this.timeline.to(this.$refs['heedarAgendaItem'+item.agendaItemId], 1, {
                    x: next1X,
                    y: next1Y,
                    width: document.getElementById('demoContainer').offsetWidth + 8,
                    height: next1Height,
                    //rotation: 360,
                    ease: Back.easeInOut, // Specify an ease
                  },
                  '-=1.0'
                  )
                }else{
                  this.timeline.to(this.$refs['heedarAgendaItem'+item.agendaItemId], 1, {
                    x: next1X,
                    y: next1Y,
                    width: next1Width,
                    height: next1Height,
                    //rotation: 360,
                    ease: Back.easeInOut, // Specify an ease
                  },
                  '-=1.0'
                  )
                }

                this.timeline.to(this.$refs['heedarAgendaItem'+item.agendaItemId], 0.5, {
                  background: 'white',
                  color: 'black',
                },
                '-=0.2' // Run the animation 0.2s early
                )
                this.timeline.to(this.$refs['heedarAgendaItemText'+item.agendaItemId], 0.5, {
                  scaleX: itemTextScaleX,
                  scaleY: itemTextScaleY,
                  y: 105,
                },
                '-=0.7' // Run the animation 0.7s early
                )

                this.timeline.to(this.$refs['iconImg'+item.agendaItemId], 1.5, {
                  scaleX: 3,
                  scaleY: 3,
                },
                '-=1.0' // Run the animation 0.5s early
                )
                this.timeline.to(this.$refs['commentsIcon'], 1, {
                  y: -18,
                  scaleX: 1,
                  scaleY: 1,
                  opacity: 1,
                  ease: Back.easeInOut,
                },
                '-=0.7' // Run the animation 0.5s early
                )

                




                this.itemStatuses[index].stateLog.push("next1")

              }else if((statusObj.newState == "next2") && (!statusObj.stateLog.includes("next2"))) {

                this.timeline.to(this.$refs['heedarAgendaItem'+item.agendaItemId], 1, {
                  x: next2X,
                  y: next2Y,
                  width: next2Width,
                  height: next2Height,
                  //rotation: 360,
                  ease: Back.easeInOut, // Specify an ease
                },
                '-=1.5'
                )
                this.timeline.to(this.$refs['heedarAgendaItem'+item.agendaItemId], 0.5, {
                  background: 'rgb(170, 170, 170)',
                  color: 'black'
                },
                '-=0.2' // Run the animation 0.5s early
                )
                this.timeline.to(this.$refs['heedarAgendaItemText'+item.agendaItemId], 0.5, {
                  scaleX: nextItemTextScaleX,
                  scaleY: nextItemTextScaleY,
                  y: 36,
                },
                '-=1.2' // Run the animation 0.5s early
                )
                this.timeline.to(this.$refs['iconImg'+item.agendaItemId], 1.5, {
                  scaleX: 1.4,
                  scaleY: 1.4,
                },
                '-=0.5' // Run the animation 0.5s early
                )
                this.itemStatuses[index].stateLog.push("next2")

              }else if((statusObj.newState == "next3") && (!statusObj.stateLog.includes("next3"))) {

                this.timeline.to(this.$refs['heedarAgendaItem'+item.agendaItemId], 1, {
                  x: next3X,
                  y: next3Y,
                  //rotation: 360,
                  width: next3Width,
                  height: next3Height,
                  ease: Back.easeInOut, // Specify an ease
                },
                '-=2.5'
                )
                this.timeline.to(this.$refs['heedarAgendaItem'+item.agendaItemId], 1.5, {
                  background: 'rgb(170, 170, 170)',
                  color: 'black'
                },
                '-=1.6' // Run the animation 0.5s early
                )
                this.timeline.to(this.$refs['heedarAgendaItemText'+item.agendaItemId], 0.5, {
                  scaleX: nextItemTextScaleX,
                  scaleY: nextItemTextScaleY,
                  y: 36,
                },
                '-=1.8' // Run the animation 0.5s early
                )
                this.timeline.to(this.$refs['iconImg'+item.agendaItemId], 1.5, {
                  scaleX: 1.4,
                  scaleY: 1.4,
                },
                '-=0.5' // Run the animation 0.5s early
                )

                this.itemStatuses[index].stateLog.push("next3")

                    
              }else if( statusObj.newState === "expired" && (!statusObj.stateLog.includes("expired")) ) {         

                var string = 'heedarAgendaItem'+item.agendaItemId
                this.timeline.to(this.$refs[string], 0, {     //IMPORTANT:  If this duration is above 0, then it takes forever to get the heedar to appear
                  opacity: 0,
                  ease: Back.easeInOut, // Specify an ease
                })
                this.itemStatuses[index].stateLog.push("expired")
              }
          

            })

            this.heedarInitialized = true
          }

        }, 1500)      //Speeding this up from 1500 to 1000
        
      },

      async initializeTimers () {
        return new Promise((resolve) => {
          //if(this.sortedAgendaItems != null) {          //I removed this line and put the new one below, was getting errors
          if(this.sortedAgendaItems.length > 0) {

            //reset the timers
            /*                                                      This was commented out on 3/31 to tackle ready state bug
            if(this.countdownTimers.length > 0) {
              this.countdownTimers.forEach((timerId) => {
                clearInterval(timerId)
              })
            }
            this.countdownTimers = []
            */
            
            //get the agenda item keys
            //const keys = Object.keys(this.agendaItems)
            this.sortedAgendaItems.forEach((key, index) => {
              this.interval.make(async () => {
              //this.countdownTimers.push(setInterval(async () => {       //the index of the countdownTimer must match the index of the item in sortedAgendaItems
                var item = this.sortedAgendaItems[index]
                var now = new Date().getTime()
                var distance = item.startTime - now
                // Time calculations for days, hours, minutes and seconds
                var days = Math.floor(distance / (1000 * 60 * 60 * 24))
                var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
                var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
                var seconds = Math.floor((distance % (1000 * 60)) / 1000)

                // Display the result in the element with id="demo"
                var timeLeft = ""
                if(days != 0) {
                  timeLeft += days + "d "
                }
                if(hours != 0) {
                  timeLeft += hours + "h "
                }
                if((minutes != 0)&&(hours == 0)) {
                  timeLeft += minutes + "m " + seconds + "s"
                }else if(minutes != 0){
                  timeLeft += minutes + "m "
                }
                if((hours == 0) && (days == 0) && (minutes == 0)) {
                  timeLeft += seconds + "s "
                }
                document.getElementById(`timer`+item.agendaItemId).innerHTML = timeLeft
                document.getElementById(`timer2`+item.agendaItemId).innerHTML = timeLeft

                //Is this the very next item?
                if(index == this.veryNextItemIndex) {
                  document.getElementById(`agendaItem`+item.agendaItemId).style.backgroundColor = "#ffffff"
                }
                if(distance > 0 && distance < 180000) {
                  document.getElementById(`agendaItem`+item.agendaItemId).style.backgroundColor = "yellow"
                  document.getElementById(`heedarAgenda`+item.agendaItemId).style.backgroundColor = "yellow"
                }
                if(distance > 0 && distance < 60000) {
                  document.getElementById(`agendaItem`+item.agendaItemId).style.backgroundColor = "orange"
                  document.getElementById(`heedarAgenda`+item.agendaItemId).style.backgroundColor = "orange"
                }
                
                // If the count down is finished, write some text
                if ((distance < 0)) {
                  
                  document.getElementById(`timer2`+item.agendaItemId).innerHTML = "";
                  if((distance < -180000) || item.isDone){
                    document.getElementById(`agendaItem`+item.agendaItemId).style.backgroundColor = "#222222"
                    document.getElementById(`agendaItem`+item.agendaItemId).style.color = "rgb(110, 110, 110)"
                    document.getElementById(`timer`+item.agendaItemId).innerHTML = "";
                    // clearInterval(this.countdownTimers[index]);
                  }else {

                    document.getElementById(`timer`+item.agendaItemId).innerHTML = "READY";
                    document.getElementById(`agendaItem`+item.agendaItemId).style.backgroundColor = "white"
                    document.getElementById(`heedarAgenda`+item.agendaItemId).style.backgroundColor = "white"

                  }
                }else if(item.isDone) {
                  document.getElementById(`agendaItem`+item.agendaItemId).style.backgroundColor = "#222222"
                  document.getElementById(`agendaItem`+item.agendaItemId).style.color = "rgb(110, 110, 110)"
                  document.getElementById(`timer`+item.agendaItemId).innerHTML = "";
                }

              }, 1000)
            })

            this.interval.make(async () => {
            //this.expiredItemsTimer = setInterval(async () => { 

              var now = new Date().getTime()
              var ready1Set = false
             // var next1Set = false
              var next2Set = false
              var next3Set = false

              var statuses = this.sortedAgendaItems.map((item, index) => {
                var distance = item.startTime - now
                if((distance < 0) || (item.isDone)) {
                  if((distance < -180000) || item.isDone ) {  //3 minutes
                    return "expired"
                  }else{
                    //It must be in a ready state
                    if(ready1Set) {
                      return "ready2"
                    }else{
                      ready1Set = true
                      return "ready1"
                    }
                  }
                }else{
                  //It is in the future
                  if(index === this.veryNextItemIndex) {               //This is likely causing next1 from not getting set in some cases because the veryNextItem does not get set
                    //next1Set = true
                    return "next1"
                  }else if(
                          ((index) < this.itemStatuses.length) && 
                          (!item.isDone) &&
                          (!next2Set)
                          ) {
                    next2Set = true
                    return "next2"
                  }else if(
                          ((index) < this.itemStatuses.length) && 
                          (!item.isDone) &&
                          (!next3Set)
                          ){
                    next3Set = true
                    return "next3"
                  }else {
                    return "active"
                  }
                }
              })

              //var xItems = this.expiredItems

              statuses.forEach((status, index) => {
                var obj = {
                  'oldState': this.itemStatuses[index].oldState,
                  'newState': this.itemStatuses[index].newState,
                  'stateLog': this.itemStatuses[index].stateLog
                }
                //expired
                if(status === "expired") {
                  //is this a change in status, 
                  if(obj.newState != "expired") {
                    //yes this is a change in status
                    obj.oldState = obj.newState
                    obj.newState = "expired"
                    this.$set(this.itemStatuses,index,obj)
                    this.expiredItems.push(this.sortedAgendaItems[index].agendaItemId)       //I believe this is a crucial line.  If I were to remove, then this.expiredItems
                  }
                }
                //ready1/ready2
                else if((status == "ready1") || (status == "ready2")) {
                  if(obj.newState != "ready") {
                    obj.oldState = obj.newState
                    obj.newState = "ready"
                    this.$set(this.itemStatuses,index,obj)
                  }
                }
                //next1
                else if(status == "next1") {
                  if(obj.newState != "next1") {
                    obj.oldState = obj.newState
                    obj.newState = "next1"
                    this.$set(this.itemStatuses,index,obj)
                  }
                }
                //next2
                else if(status == "next2") {
                  if(obj.newState != "next2") {
                    obj.oldState = obj.newState
                    obj.newState = "next2"
                    this.$set(this.itemStatuses,index,obj)
                  }
                }
                //next3
                else if(status == "next3") {
                  if(obj.newState != "next3") {
                    obj.oldState = obj.newState
                    obj.newState = "next3"
                    this.$set(this.itemStatuses,index,obj)
                  }
                }


              })

              //this.expiredItems = xItems


              //add code that clears
              //if(this.expiredItems.length == this.sortedAgendaItems.length) {
              //  clearInterval(this.expiredItemsTimer)
              //}
            }, 1000)

            this.interval.make(async () => {
            //this.heedarResetTimer = setInterval(async () => { 
              if(this.savedWindowSize == null) {
                //first time through, dont reload/compare
                this.savedWindowSize = {
                  'width': window.innerWidth || document.body.clientWidth,
                  'height': window.innerHeight || document.body.clientHeight
                }
              }
              var size = {
                'width': window.innerWidth || document.body.clientWidth,
                'height': window.innerHeight || document.body.clientHeight
              }
              if(this.savedWindowSize.width != size.width) {
                
                this.initializeConsole()
                this.rotateStar()
              }
            }, 2000)


            //Every 4 minutes, refresh the heedar
            this.interval.make(async () => {
              this.rotateStar()
              this.initializeConsole()
            }, 240000)

            

            resolve()
          }
        })
      },

      async uploadImg() {
        if(event.target.files[0] == "") {return}
        var storageRef = firebase.storage().ref();
        //var imageName = null
        var uploadTask = null
        var metadata = null
        
        this.imageData = event.target.files[0]

        //compress
        const width = 400
        const reader = new FileReader()
        reader.readAsDataURL(this.imageData)
        reader.onload = async (event) => {
          const img = new Image()
          img.src = event.target.result
          img.onload = async () => {
            const scaleFactor = width / img.width
            const elem = document.createElement('canvas')
            elem.width = width
            elem.height = scaleFactor * img.height
            const ctx = elem.getContext('2d')
            ctx.drawImage(img, 0, 0, width, scaleFactor*img.height)
            ctx.canvas.toBlob(async(blob) => {
              //imageName = 'agendaItemImg'
              metadata = { 'contentType': this.imageData.type};
              uploadTask = storageRef.child(`heedarAgendaItemImages/${this.profile.uid}/${this.selectedAgendaItem.routineTemplateId}_${this.selectedAgendaItem.routineTemplateMomentId}`)
                                      //.put(this.imageData, metadata)
                                      .put(blob, metadata)

              uploadTask.on(`state_changed`, async (snapshot) => {
                //this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
                switch (snapshot.state) {
                    case firebase.storage.TaskState.PAUSED: // or 'paused'
                    //console.log('Upload is paused');
                    break;
                    case firebase.storage.TaskState.RUNNING: // or 'running'
                    //console.log('Upload is running');
                    break;
                }
              },async () => {
                //console.log(error.message)
              },async () => {
                  //this.uploadValue=100;
                  uploadTask.snapshot.ref.getDownloadURL().then(async(url) => {



                      var ref = await db.collection('users').doc(this.profile.uid).collection('routineTemplates').doc(this.selectedAgendaItem.routineTemplateId)
                      document.getElementById("file-input1").value = "";
                      await ref.set({
                          'routineImage': url,

                      }, {merge: true})


                      //update the item routineImages.
                      await this.sortedAgendaItems.forEach(async(item) => {
                        if(item.routineTemplateId == this.selectedAgendaItem.routineTemplateId) {
                          //update the current dailyAgendaReport
                          var ref2 = await db.collection('users').doc(this.profile.uid).collection('dailyAgendaReports').doc(this.todayDayStamp)
                          await ref2.set({
                            'agendaItems': {
                              [item.agendaItemId]: {
                                'routineImage': url,
                              }
                            }
                          }, {merge: true})
                          
                        }
                      })
                      


                      
                      // console.log('File available at', url);
                      this.agendaDetailSheet = false;
                  })
                },
              );
            }, 'image/jpeg', 1)
          }
        }
        reader.onerror = (error) => (error)
      },

      async initializeConsole () {
        return new Promise((resolve) => {
          
          ( async () => {
            this.clearConsole()
            try{
              this.dailyAgendaReport = await this.getDailyAgendaReport2()
            }catch(e){
              resolve()
            }
            
            await this.initializeStatuses2()        //Newly added, testing
            await this.initializeTimers()
            this.initializeAnimationRunner2()       //Newly added, testing

            resolve()

          })()
        })
      },

      clearConsole () {
        this.interval.clearAll()
        /*
        window.clearInterval(this.animationTimer)
        if(this.countdownTimers.length > 0) {
          this.countdownTimers.forEach((timerId) => {
            window.clearInterval(timerId)
          })
        } */
        this.resetAgendaItems()
        // this.countdownTimers = []
        this.timeline = null
        this.timeline2 = null
        //window.clearInterval(this.expiredItemsTimer)
        //window.clearInterval(this.heedarResetTimer)
        //window.clearInterval(this.newDayTimer)
        //window.clearInterval(this.animationTimer)
        this.animationTimer = null
        // this.heedarResetTimer = null
        this.expiredItemsTimer = null
        // this.newDayTimer = null
        this.savedWindowSize = null
        this.savedDayStamp = null
      },
      checkNotifications () {
        if(!this.isValid) {
          this.refreshNotifications()
        }else if((this.permission === 'denied') || (this.permission === 'default')) {
          this.refreshNotifications()
        }
      },

      rotateStar () {
        var starTimeline = new TimelineLite()
        starTimeline.to(this.$refs['star'], 0, {
          rotation: -10,
          opacity: 1,
          scaleX: .75,
          scaleY: .75,
        })
        starTimeline.to(this.$refs['star'], 3, {
                  rotation: 10,
                  ease: Back.easeInOut, // Specify an ease
                  opacity: 0.07,
                  scaleX: 1.8,
                  scaleY: 1.8,
                })
      },

      dropDownDayPlanClicked () {
        this.dropDownDayPlanActive = !this.dropDownDayPlanActive
        //if(this.dropDownDayPlanActive) {
        localStorage.setItem('dropDownDayPlanActive', JSON.stringify(this.dropDownDayPlanActive))
        //}else{

        //}
        
      },
      dropDownPacingClicked () {
        this.dropDownPacingActive = !this.dropDownPacingActive
        localStorage.setItem('dropDownPacingActive', JSON.stringify(this.dropDownPacingActive))
      },
      dropDownRoutinesClicked () {
        this.dropDownRoutinesActive = !this.dropDownRoutinesActive
        localStorage.setItem('dropDownRoutinesActive', JSON.stringify(this.dropDownRoutinesActive))
      },

      

    },
    beforeCreate () {
      
      if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
      if(!this.$store.state.routine) this.$store.registerModule('routine', routineModule)
      if(!this.$store.state.notification) this.$store.registerModule('notification', notificationModule)
      if(!this.$store.state.outcome) this.$store.registerModule('outcome', outcomeModule)

    },
    async created () {
      //Preload details data
      /* eslint-disable no-console */
      if(!this.actionWakeHappinessScores) {
        setTimeout(() =>{ 
          this.getActionWakeHappinessData()
        }, 15000);
      }
      if(!this.actionWakeOutcomeReports) {
        setTimeout(() =>{ 
          this.getActionWakeOutcomeData()
        }, 15000);
      }
      if(!this.actionWakeWorkSessionItems) {
        setTimeout(() =>{ 
          this.getActionWakeWorkSessionData()
        }, 15000);
      }
    },
    async mounted () {
      this.rotateStar()
      this.initializeConsole()
      this.checkNotifications()


      //might remove later
      var command= (this.$route.params.command) ? this.$route.params.command : null
      if(command){
        if(command == "didit") {
          var id = this.$route.params.dayStamp + "#" + this.$route.params.routineTemplateMomentId
          var item = this.sortedAgendaItems.filter(item => item.agendaItemId === id)
          this.selectedAgendaItem = item[0]    //I'm assuming the first item id get filtered out, may need to check this
          this.$nextTick(() => {
            this.agendaDetailSheet = true
          });
        }
      }
      //end - might remove later


      //Pull some console state from localStorate
      if(localStorage.getItem("dropDownPacingActive") != null) {
        this.dropDownPacingActive = JSON.parse(localStorage.getItem("dropDownPacingActive"))
      }
      if(localStorage.getItem("dropDownRoutinesActive") != null) {
        this.dropDownRoutinesActive = JSON.parse(localStorage.getItem("dropDownRoutinesActive"))
      }
      if(localStorage.getItem("dropDownDayPlanActive") != null) {
        this.dropDownDayPlanActive = JSON.parse(localStorage.getItem("dropDownDayPlanActive"))
      }


      

    },
    updated () {
      
    },
    beforeDestroy () {
      this.clearConsole()
      

    },
    destroyed () {
      this.clearConsole()
    }
  }
</script>

<style scoped>



.sectionHolder {
  border-top: 1px solid rgb(41, 41, 41);
  position: relative;
  max-width: 900px;
  margin: 0 auto;
}
.sectionTitle {
  margin-top: 10px;
  margin-left: 20px;
  margin-bottom: 20px;
  color: #8a8a8a;
  position: relative;
}
.sectionExpandedArea {
  background-color: transparent;
  margin: 5px;
  margin-bottom: 34px;
}
.sectionChevron {
  color: #5e5e5e;
  display: inline-block;
  position: absolute;
  right: 16px;
  top: 8px;
  background-color: transparent;
  text-align: right;
}
.actionWakeButton {
  font-size: .65em;
  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;
  position: absolute;
  top: 19px;
  right: 17px;
  border: solid 1px rgb(156, 156, 156);
}
.actionWakeButton > a {
  text-decoration: none;
  color: rgb(156, 156, 156);
}

.heedarConsole {
  display: inline-block;
  padding-bottom: 50px;
  /*position: absolute; /* Sit on top of the page content */
  /*display: none; /* Hidden by default */
  /*width: 100%; /* Full width (cover the whole page) */
  /*height: 100%; /* Full height (cover the whole page) */
  /*top: 0;
  left: 0;
  right: 0;
  bottom: 0;*/
  background-color: rgba(0, 0, 0, 0.97); /* Black background with opacity */
  /*z-index: 1100; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  vertical-align: top;
  font-size: 1.5em;
  text-align: left;
  overflow: hidden;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.heedarOverlay::-webkit-scrollbar {
  display: none;
}
.heedarConsole::-webkit-scrollbar {
  display: none;
}
.demoContainer {
  position: relative;
  background-color: #1b1b1b;
  height: 280px;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
}
.addRoutineButton {
  background-color: yellow;
  text-align: right;
}
.starBackground {
  opacity: 0.1;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  
}
.iconBackgroundInItem {
  opacity: 0.09;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.commentsIcon {
  position: absolute;
  left: 25px;
  transform: translateX(-50%);
  top: 25px;
  opacity: 0;
}
.starImg {
  width: 200px;
  height: 200px;
  opacity: 0.5;
}
.verySmallStarImg {
  width: 40px;
  width: 40px;
}
.iconImg {
  width: 70px;
  height: 70px;
}

.commentsIconImg {
  width: 24px;
  height: 24px;
}
.commentsIconImg2 {
  
  color: black;
  font-size: 1.7em;
}
.commentIconHolder {
  position: relative;
}
.starAlertHolder {
  position: absolute;
  top: -6px;
  right: -6px;
}
.starAlertImg {
  width: 24px;
  height: 26px;
}


.intentMeanCategory {
  position: absolute;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  bottom: 3px;
  left: 50%;
  transform: translateX(-50%);
  color: rgba(134, 134, 134, 0.8);
}



.box {
  line-height: 1.2em;
  width: 70px;
  height: 70px;
  display: block;
  opacity: 1;
  text-align: center;
  background-color: rgb(255, 0, 0);
  padding: 3px;
  font-size: .65em;
  font-weight: 500;
  position: absolute;
  border: rgb(0, 0, 0) 3px solid;
  overflow: hidden;
  margin: 8px;
}
.countdown {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  margin-top: 4px;
}
.countdown2 {
  font-weight: 400;
}


.onDeckContainer {
  background-color: rgb(229, 255, 0);
  font-weight: 900;
  position: absolute;
  right: 0;
  top: 30px;
  height: 200px;
  z-index: 3;
}
.onDeckContainerGradient {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(to bottom, rgba(34,34,34,0), rgba(34,34,34,1));
}

.stepsHolder {
  max-width: 1200px;
  background-color: white;
  margin: 0 auto;
  font-size: .55em;
  padding-left: 20px;
  padding-right: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}
.stepsHolder2 {
  padding-top: 10px;
}

.heedarHeader {
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  padding: 15px;
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
}
.agendaItemTextWrapper {
  line-height: 14px;
}
.heedarItemTitle {
  font-family: 'Kreon', serif;
  font-size: 1.05em;
  font-weight: 700;
  width: 50%;
  margin: 0 auto;
}
.heedarItemSubtitle {
  font-family: 'Quicksand', sans-serif;
  font-size: .6em;
  font-weight: 400;
  width: 70%;
  margin: 0 auto;
  margin-top: 5px;
  background-color: transparent;
  line-height: 8px;
}
.heedarItemImage {
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  height: 100%;
  width: 100%
}
.heedarDidItButton {
  max-width: 1100px;
  padding: 5px;
  width: 100%;
  background-color: white;
  margin: 0 auto;
  font-size: .75em;
  font-weight: 700;
  text-align: center;
}

/* Active Routines */
.activeRoutines {
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  margin-bottom: 70px;
  background-color: transparent;
  padding: 10px;

}


/* Day Plan */
.dayPlanHeaderBanner {
  position: relative;
  background-color: transparent;
  margin-bottom: 20px;
  margin-top: 10px;
  vertical-align: top;
  width: 100%;
}
.heediInsightLogo {
  display: inline-block;
  background-color: transparent;
  margin-right: 10px;
  vertical-align: top;
}
.heediInsightLogo > img {
  height: 55px;
}
.agendaTitle {
  display: inline-block;
  background-color: transparent;
  width: 300px;
}
.agendaTitleDate {
  color:#8a8a8a;
  font-size: .70em;
  font-weight: 600;
}
.agendaTitleText {
  background-color: transparent;
  color:#8a8a8a;
  font-weight: 400;
  font-size: 1em;
}
.comingUp {
  display: inline-block;
  width: 130px;
  background-color: transparent;
  position: absolute;
  bottom: 10%;
  right: 2%;
  padding: 5px;
  text-align: center;
  border: 1px solid #333333;
}
.comingUpTitle {
  background-color: transparent;
  color:rgb(117, 117, 117);
  font-size: .5em;
  width: 100%;
  font-style: italic;
  text-align: center;
  
}
.comingUpValue {
  color:#cccccc;
  font-size: .70em;
  font-weight: 500;
  width: 100%;
  margin-top: 2px;
}

#veryNextItemCountdown {
  font-size: 1.8em;
}


.momentTitle {
  display: inline-block;
  text-align: left;
  font-weight: 400;
  font-size: 1em;
  padding-left: 25px;
  padding-top: 20px;
  background-color:transparent;
  display: block;
  color:rgb(143, 143, 143);
  width: 80%;
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}


.stepItem {
  color: black;
  font-size: 1.2em;
  font-weight: 500;
  width: 100%;
  background-color: white;
  margin-bottom: 5px;
}
.outcomeFormHolder {
  background-color: white;
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
}

.outcomeFormStep {
  width: 100%;
  padding: 2px;
  display: inline-block;
  font-size: .8em;
  vertical-align: top;
  background-color: transparent;
  height: 140px;
}


.outcomeInput {
  width: 15%;
  display: inline-block;
  background-color: transparent;
}

.outcomeInputUom {
  width: 15%;
  padding: 2px;
  display: inline-block;
  font-size: .5em;
  vertical-align: middle;
  background-color: transparent;
}

.bottomSheetContainer {
  height: 900px;
  width: 100%;
  position: relative;
  background-color: white;
}



.wrapper {  
  margin: 0 auto;
  padding: 0;
}

a {
  color: #777;
}


.agendaDetailSheet {
  text-align: center;
  height: 100%;
  display: relative;
}





.dayPlanArea {
  /*position: fixed;*/
  width: 100%;
  max-width: 1100px;
  top: 0;
  background-color: transparent;
  height: 100%;
  margin: 0 auto;
  padding: 10px;
}


.dayPlanContainer {
  width: 100%;
  background-color: transparent;
  max-width: 1100px;
  margin: 0 auto;
  height: 40%;
}




.timelineContainer {
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  align-items: center;
  background-color: black;
  height: 100%;
}
.timelineContainer2 {
  display: grid;
  grid-template-columns: auto auto auto auto auto;
  grid-template-rows: auto auto;
  align-content: center;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
}
.timelineItem {
  position: relative;
  text-align: center;
  background-color: rgb(170, 170, 170);
  padding: 3px;
  font-size: .60em;
  border: 1px solid rgba(0, 0, 0, 0.8);
  min-height: 100px;
  
}
.checkmark {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.starmark {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}
.verySmallStarImg {
  width: 20px;
  width: 20px;
  opacity: 0.5;
}

.main-img {
  object-fit: cover;
  max-width: 500px;
}
.itemTitle {
  font-family: 'Quicksand', sans-serif;
  text-align: center;
  max-width: 100px;
  width: 90%;
  margin: 0 auto;
  font-weight: 600;
}
.countdown {
  font-size: .80em;
}

.nextItemImage {
  color: white;
}

.switchColumn {
    width: 15%;
    min-width: 40px;
    background-color: transparent;
    display:inline-block;
    text-align: right;
}
.switchLabel {
    width: 80%;
    background-color: transparent;
    display: inline-block;
    font-size: 2em;
    font-weight: 800;
    text-align: left;
    padding-left: 20px;
}
.switchHolder {
    margin: 0 auto;
    max-width: 200px;
    background-color: transparent;
}

.picIconHolder {
    font-size: 2em;
    color: rgb(73, 73, 73);
    width: 100px;
    background-color: white;
    margin: 0 auto;
    text-align: center;

}
.item-img {
  width: 95%;

}
#panel-close {
    position: absolute;
    right: 13px;
    top: 10px;
    color: #969696;
    font-size: 2.2em;
    z-index: 100;
}


.fade-enter-active, .fade-leave-active {
  transition: all 1.5s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-1%);
}

.fade2-enter-active, .fade2-leave-active {
  transition: all 2.3s ease-in-out;
}
.fade2-enter, .fade2-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-1px);
}


.routineCommentsHolder {
  padding: 0;
}
.headline {
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  font-size: .9em;
}
.commentBoxHolder {
  position: relative;
  padding: 5px;
  z-index: 100;
  width: 100%;
  background-color: white;
  padding-top: 20px;
  padding-left: 20px;
  padding-bottom: 20px;
  border-top: 1px solid rgb(212, 212, 212);
}
.commentDateHolder {
  display: inline-block;
  position: absolute;
  z-index: 79;
  top: -3px;
  left: -3px;
  padding: 5px;
  background-color: transparent;
  font-family: 'Quicksand';
  font-weight: 400;
  font-size: .8em;
}
.routineCommentsHolder > ul {
  list-style-type: none;
  vertical-align: top;
}


.eodText {
  font-family: 'Kreon', serif;
  color: rgb(170, 170, 170);
  font-size: 1.4em;
  font-weight: 700;
  position: absolute;
  opacity: 0;
}
.end {
  font-size: 1.8em;
  top: 0;
  left: 25%;
}
.of {
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.day {
  font-size: 1.8em;
  top: 0;
  right: 25%;
}






@media screen and (min-width: 320px) {
  .timelineContainer2 {
    grid-template-columns: auto auto auto auto auto auto;
    grid-template-rows: 110px 110px;
  }
  .end {
    left: 27%;
  }
  .day {
    right: 27%;
  }

  
}

@media screen and (min-width: 360px) {
  .agendaTitleDate {
    font-size: .9em;
  }
}

@media screen and (min-width: 375px) {
  .heedarItemTitle {
    width: 55%;
  }
}


@media screen and (min-width: 520px) {
  .end {
    left: 28%;
  }
  .day {
    right: 28%;
  }

  .heediSections {
    margin-top: 65px;
  }

  
}



@media screen and (min-height: 660px) {
  .agendaDetailSheet {
    height: 740px;
  }
  
}

@media screen and (min-height: 720px) {

  .agendaDetailSheet {
    height: 740px;
  }
  
  
  
}





@media screen and (min-width: 768px) {

  .upperContainer {
    margin-top: 32px;
    height: 58%
  }
  
  .upperHeedarArea {
    padding: 20px;
  }
  .timelineItem {
    font-size: .7em;
  }
  .item-img {
    width: 50%;

  }
  .momentTitle {
    text-align: center;
  }
  .end {
    left: 38%;
  }
  .day {
    right: 38%;
  }

  .commentsIconImg2 {
    font-size: 2em;
  }


  .heedarItemTitle {
    width: 50%;
  }
  
  
}


@media screen and (min-height: 800px) {
  .agendaDetailSheet {
    height: 700px;
  }


  
}

@media screen and (min-width: 1000px) {

  .upperContainer {
    margin-top: 32px;
    height: 55%;
  }
  .timelineContainer2 {
    grid-template-columns: auto auto auto auto auto auto auto auto;
    grid-template-rows: 140px 140px;
    align-content: center;
  }
  .end {
    left: 40%;
  }
  .day {
    right: 40%;
  }

  
  
}

@media screen and (min-height: 1000px) {
  .agendaDetailSheet {
    height: 800px;
  }
}


@media screen and (min-width: 1024px) and (max-width: 1024px) and (min-height: 1360px) {
  .upperContainer {
    height: 68%;
  }
  .timelineContainer2 {
    grid-template-columns: auto auto auto auto auto auto;
    grid-template-rows: 140px 140px;
    align-content: center;
  }
  .timelineItem {
    font-size: .85em;
  }
}

@media screen and (min-width: 1100px) {

  .upperContainer {
    margin-top: 15px;
    height: 59%;
  }
  .heedarItemTitle {
    font-size: 1.15em;
  }
  .timelineItem {
    font-size: .85em;
  }
  
}

@media screen and (min-width: 1300px) {
  .timelineContainer2 {
    grid-template-columns: auto auto auto auto auto auto auto auto auto auto;
    grid-template-rows: 140px 140px;
    align-content: center;
  }
}

@media screen and (min-height: 1300px) {
  .agendaDetailSheet {
    height: 1100px;
  }
}
</style>
<template>
  <div class="wrapper">
    <div class="card">
      <div v-if="currentView=='INSERT_VIEW_HERE'">
         <!-- Six Articles and MevChain -->
     <br>
      <section class="cards" id="articleConsole">

        <!--
        <article>
          <router-link to="/evolve/drink_water/PCS8XNWiwBw9r0iAhluf">
            <div class="item">
              <img class="article-img" src="@/assets/articleImages/drink_water.png" alt="Photo credit of glasses of water belongs to Jana Sabeth on Unsplash.com" />
              <h1 class="article-title">
                Drink more water
              </h1>
            </div>
          </router-link>
        </article>
        -->
          
        
          <div v-for="(dataSet, index) in dataSets" v-bind:key="`dataSet1`+index">
              <div class="item">
                <GChart
                  :type="dataSet.type"
                  :data="dataSet.chartData"
                  :options="dataSet.chartOptions"
                />
              </div>
          </div>
        
          <div v-for="(dataSet2, index2) in dataSetsForEventFollowUp" v-bind:key="`dataSet2`+index2">
              <div class="item">
                <GChart
                  :type="dataSet2.type"
                  :data="dataSet2.chartData"
                  :options="dataSet2.chartOptions"
                />
              </div>
          </div>
        
      </section>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-empty */
  //Some example imports you could have:
  import {GChart} from 'vue-google-charts'
  import moment from 'moment-timezone'
    //import Worker from "worker-loader!@/worker";
    //import categoryModule from '@/store/category'
    //import '@/plugins/vuetify'
    //import MevChains from '@/components/MevChains'
    //import { mapGetters, mapActions } from 'vuex'

  export default {
    name: "OutcomeCharts",
    components: {
      GChart
    },
    props: {
      outcomesArray: {
        type: Array
      },

      veryNextItem: {
        type: Object
      }
    },
    data () {
      return {
        currentView: 'INSERT_VIEW_HERE',

        isDarkMode: true,
        
        
      }
    },
    computed: {
      thisWeekEndTime () {
        //console.log("thisWeekEndTime",moment().endOf('isoWeek').format())
        return moment().endOf('isoWeek').valueOf()
      },
      thisWeekStartTime () {
        //console.log("thisWeekStartTime", moment().startOf('isoWeek').format())
        return moment().startOf('isoWeek').valueOf()
      },
      lastWeekStartTime () {
        //console.log("lastWeekStartTime", moment().startOf('isoWeek').subtract(1, 'weeks').format())
        return moment().startOf('isoWeek').subtract(1, 'weeks').valueOf()
      },
      lastWeekEndTime () { 
        //console.log("lastWeekEndTime", moment().endOf('isoWeek').subtract(1, 'weeks').format())
        return moment().endOf('isoWeek').subtract(1, 'weeks').valueOf()
      },
      last30DayStartTime () {
        return moment().subtract(1, 'month').valueOf()
      },
      nowTime () { 
        return moment().valueOf()
      },
      thisWeekOutcomes () {
        if(this.outcomesArray) {
          var thisWeekOutcomes = this.outcomesArray.filter((item) => {
            return (this.thisWeekStartTime < item.momentStartTime) && (item.momentStartTime < this.thisWeekEndTime)
          })
          return thisWeekOutcomes
        }else{
          return null
        }
      },
      thisWeekHappinessOutcomes () {
        if(this.outcomesArray) {
          var thisWeekHappinessOutcomes = this.outcomesArray.filter((item) => {
            return (this.thisWeekStartTime < item.momentStartTime) && (item.momentStartTime < this.thisWeekEndTime) && (item.outcomeFeeling)
          })
          return thisWeekHappinessOutcomes
        }else{
          return null
        }
      },
      lastWeekOutcomes () {
        if(this.outcomesArray) {
          var lastWeekOutcomes = this.outcomesArray.filter((item) => {
            return (this.lastWeekStartTime < item.momentStartTime) && (item.momentStartTime < this.lastWeekEndTime)
          })
          return lastWeekOutcomes
        }else{
          return null
        }
      },
      last30DayOutcomes () {
        if(this.outcomesArray) {
          var last30DayOutcomes = this.outcomesArray.filter((item) => {
            return (this.last30DayStartTime < item.momentStartTime) && (item.momentStartTime < this.thisWeekEndTime)
          })
          return last30DayOutcomes
        }else{
          return null
        }
      },
      lastWeekHappinessOutcomes () {
        if(this.outcomesArray) {
          var lastWeekHappinessOutcomes = this.outcomesArray.filter((item) => {
            return (this.lastWeekStartTime < item.momentStartTime) && (item.momentStartTime < this.lastWeekEndTime) && (item.outcomeFeeling)
          })
          return lastWeekHappinessOutcomes
        }else{
          return null
        }
      },
      dataSetsForEventFollowUp () {
        if(this.outcomesArray) {
          /* eslint-disable no-unused-vars */
          var dataSetsArray = []

          var dataHolder = {}

          
          this.last30DayOutcomes.forEach(outcome => {            //may want to increase this
            if(outcome.routineType === 'event follow-up') {
              //var dow = moment(outcome.dateCreated.toDate()).isoWeekday()
              outcome.outcomes.forEach( out => {
                if(out.stepType === 'eventFollowUpQuestion') {
                  //initialize an array for the variableValue if undefined
                  if(!(dataHolder[out.stepValue.variableValue])) {
                    dataHolder[out.stepValue.variableValue] = []
                  }
                  //get data out of outcome and put in dataHolder
                  var obj = {
                    'value': out.stepValue.value,
                    'dateStamp': moment(outcome.dateCreated.toDate()).valueOf(),
                    'date': outcome.dateCreated,
                    'uom': out.stepValueUom
                  }
                  dataHolder[out.stepValue.variableValue].push(obj)
                }
              }) 
            }
          })

          /* eslint-disable no-console */
          //console.log("dataHolder",dataHolder)

          Object.keys(dataHolder).forEach(key => {
            var aDataSet = makeEventFollowUpDataSet({'data':dataHolder, 'key':key})
            dataSetsArray.push(aDataSet)
          })

          return dataSetsArray
        }else{
          return []
        }
        /* eslint-disable no-unused-vars */
        function getSum(total, num) {
          return total + Math.round(num);
        }
        function makeEventFollowUpDataSet (payload) {

          var lineChartOptions = {
            title: payload.key,
            titleTextStyle: {color: '#757575'},
            backgroundColor: {fill: '#000000',fillOpacity: 1.0},
            series: {0: { color: '#f0f0f0'},1: { color: '#ff0000'} },
            hAxis: {textStyle: {color: '#757575'} },
            vAxis: {textStyle: {color: '#575757'},gridlines: {color: "#575757"} },
            legend: {position: 'top', maxLines: 3, textStyle: {color: '#757575'} },
          }
          var chartOptions = {
            title: 'Total per day',
            titleTextStyle: {color: '#757575'},
            backgroundColor: {fill: '#000000',fillOpacity: 1.0},
            series: {0: { color: '#f0f0f0'},1: { color: '#ff0000'} },
            hAxis: {textStyle: {color: '#757575'} },
            vAxis: {textStyle: {color: '#575757'},gridlines: {color: "#575757"} },
            legend: {position: 'top', maxLines: 3, textStyle: {color: '#757575'} },
          }

          //payload.series1Data
          //payload.series2Data
          var dataObjArray = payload.data[payload.key]
          var dataHolder2 = {}

          /* eslint-disable no-console */
          //console.log("dataObjArray", dataObjArray)

          var chartDataArray = []
          var arr2 = []
          arr2.push('Date')
          dataObjArray.forEach(obj => {
            if(!dataHolder2[obj.uom]) {
              dataHolder2[obj.uom] = []
              arr2.push(obj.uom)
            }
            dataHolder2[obj.uom].push(obj)
          })
          
          chartDataArray.push(arr2)


          //number of data series

          var seriesCount = Object.keys(dataHolder2).length
          //console.log("seriesCount", seriesCount)
          //console.log("dataHolder2", dataHolder2)


          if(seriesCount > 0) {


            //get length of first series, that will be the number of cycles to build out this array
            
            var firstSeriesArray = null
            var otherSeriesArray = []
            //iterate through the series
            var keys = Object.keys(dataHolder2)
            for(var j = 0; j < seriesCount; j++) {
              if(j === 0) {
                firstSeriesArray = dataHolder2[keys[j]] 
              }else{
                otherSeriesArray.push(dataHolder2[keys[j]])
              }
            }

            //console.log("firstSeriesArray", firstSeriesArray)
            //console.log("otherSeriesArray", otherSeriesArray)

            firstSeriesArray.reverse()
            firstSeriesArray.forEach((dataPoint, index) => {
              var arr1 = []
              var aDate = moment(dataPoint.date.toDate())
              var formattedDate = aDate.format("MM")+"/"+aDate.format("DD")
              arr1.push(formattedDate)

              //missing first series
              arr1.push(dataPoint.value)

              if(otherSeriesArray.length > 0) {
                otherSeriesArray.forEach(series => {
                  //series.reverse()
                  //find the corresponding dataPoint with same formattedDate in each series
                  var matchingDataPoint = series.find(dataPoint => {
                    var aDate2 = moment(dataPoint.date.toDate())
                    var formattedDate2 = aDate2.format("MM")+"/"+aDate2.format("DD")
                    return formattedDate2 === formattedDate
                  })
                  if(matchingDataPoint) {
                    arr1.push(matchingDataPoint.value)
                  }else{
                    arr1.push(arr1[arr1.length-1])  //repeat a previous value
                  }
                  
                })
              }

              //console.log("arr1", arr1)

              chartDataArray.push(arr1)

            })
            
            /* OLD CODE
            //need to reverse so that dates are in order left to right
            dataObjArray.reverse()
            dataObjArray.forEach(dataPoint => {
              var arr = []
              var aDate = moment(dataPoint.date.toDate())
              var formattedDate = aDate.format("MM")+"/"+aDate.format("DD")
              arr.push(formattedDate)
              arr.push(dataPoint.value)
              chartDataArray.push(arr)
            })
            */
          }


          var options = lineChartOptions
          var obj = {
            'chartData': chartDataArray,
            'chartOptions': options,
            'type': 'LineChart',
          }
          return obj
        }
      },
      dataSets () {
      
        if(this.outcomesArray) {
          var dataSetsArray = []

          //Happiness data
          if(this.veryNextItem.routineType != 'work sessions') {

            //get happiness dataSeries

            //THIS WEEK HAPPINESS OUTCOMES
            //THIS WEEK HAPPINESS OUTCOMES
            var series2DataArray = [ [],[],[],[],[],[],[] ]
            this.thisWeekHappinessOutcomes.forEach(outcome => {
              var dow = moment(outcome.dateCreated.toDate()).isoWeekday()
              series2DataArray[dow-1].push(outcome.outcomeFeeling.feelingValue)
            })

            var series2DataReduced = series2DataArray.map((array) => {
              var x = (array.length > 0) ? (array.reduce(getSum, 0) / array.length) : 0
              return x     //This will return the average score, or zero
            })


            //LAST WEEK HAPPINESS OUTCOMES
            //LAST WEEK HAPPINESS OUTCOMES
            var series1DataArray = [ [],[],[],[],[],[],[] ]
            this.lastWeekHappinessOutcomes.forEach(outcome => {
              var dow = moment(outcome.dateCreated.toDate()).isoWeekday()
              series1DataArray[dow-1].push(outcome.outcomeFeeling.feelingValue)
            })

            var series1DataReduced = series1DataArray.map((array) => {
              var y = (array.length > 0) ? array.reduce(getSum, 0) / array.length : 0
              return y
            })



          
            var aDataSet = makeDataSet({
              type: 'LineChart', 
              series1Data: series1DataReduced, 
              series2Data: series2DataReduced,
              momentTitle: this.veryNextItem.momentTitle,
              })
            dataSetsArray.push(aDataSet)
          }

          
          //Outcome data
          if(
            (this.veryNextItem.routineType === 'exercise') || 
            (this.veryNextItem.routineType === 'exercise2') ||
            (this.veryNextItem.routineType === 'cessation') || 
            (this.veryNextItem.routineType === 'everyday habit') ||
            (this.veryNextItem.routineType === 'work sessions') ||
            (this.veryNextItem.routineType === 'cooking') ||
            (this.veryNextItem.routineType === 'generic')
          
          ) {

              var series1DataReduced2 = [ 0,0,0,0,0,0,0 ]
              var series2DataReduced2 = [ 0,0,0,0,0,0,0 ]
              // THIS WEEK
              // THIS WEEK
              var series2DataArray2 = [ [],[],[],[],[],[],[] ]
              this.thisWeekOutcomes.forEach(outcome => {
                var dow = moment(outcome.dateCreated.toDate()).isoWeekday()
                if( (outcome.routineType === 'exercise') ) {
                  var total = 0
                  outcome.outcomes.forEach( out => {
                    if(out.stepType === 'action') {
                      total += out.stepValueA
                    }
                  })
                  series2DataArray2[dow-1].push(total)

                }else if ( (outcome.routineType === 'exercise2') || (outcome.routineType === 'generic') ) {
                  
                  //TODO:  chart not looking at right outcomes
                  
                  
                  if (this.veryNextItem.momentSteps) {
                    var total2 = 0
                    outcome.outcomes.forEach( out => {
                      if (this.veryNextItem.momentSteps[0].stepActionId === out.stepActionId) {
                        if(out.stepType === 'action') {
                          total2 += out.stepValueA
                        }
                      }
                    })
                    series2DataArray2[dow-1].push(total2)
                    
                  }
                  
                }else if( 
                  (outcome.routineType === 'cessation') || 
                  (outcome.routineType === 'everyday habit') || 
                  (outcome.routineType === 'work sessions') ||
                  (outcome.routineType === 'cooking')
                  ) {
                  series2DataArray2[dow-1].push(outcome.outcomeActionStepValueTotal)
                  series2DataReduced2 = null
                  series2DataReduced2 = series2DataArray2.map((array) => {
                    return array.reduce(getSum, 0)
                  })

                }
              })


              // LAST WEEK
              // LAST WEEK
              var series1DataArray2 = [ [],[],[],[],[],[],[] ]
              this.lastWeekOutcomes.forEach(outcome => {
                var dow = moment(outcome.dateCreated.toDate()).isoWeekday()
                if( (outcome.routineType === 'exercise') ) {
                  var total6 = 0
                  outcome.outcomes.forEach( out => {
                    if(out.stepType === 'action') {
                      total6 += out.stepValueA
                    }
                  })
                  series1DataArray2[dow-1].push(total6)

                }else if ( (outcome.routineType === 'exercise2') || (outcome.routineType === 'generic')) {
                  
                  //TODO:  chart not looking at right outcomes
                  if (this.veryNextItem.momentSteps) {
                    var total3 = 0
                    outcome.outcomes.forEach( out => {
                      if (this.veryNextItem.momentSteps[0].stepActionId === out.stepActionId) {
                        if(out.stepType === 'action') {
                          total3 += out.stepValueA
                        }
                      }
                    })
                    series1DataArray2[dow-1].push(total3)
                    
                  }
                

                }else if( (outcome.routineType === 'cessation') || (outcome.routineType === 'everyday habit') || (outcome.routineType === 'work sessions') ) {
                  series1DataArray2[dow-1].push(outcome.outcomeActionStepValueTotal)
                  series1DataReduced2 = null
                  series1DataReduced2 = series1DataArray2.map((array) => {
                    if(array.length > 0) {
                      return array.reduce(getSum, 0)
                    }else{
                      return 0
                    }
                    
                  })
                }
              })



              //Make the DataSet
              if(
                (this.veryNextItem.routineType === 'cessation') || 
                (this.veryNextItem.routineType === 'everyday habit') ||
                (this.veryNextItem.routineType === 'work sessions')
              ) {

                dataSetsArray.push(makeDataSet({
                    type: 'ColumnChart', 
                    series1Data: series1DataReduced2, 
                    series2Data: series2DataReduced2,
                    momentTitle: this.veryNextItem.momentTitle,
                    routineType: this.veryNextItem.routineType,
                    }))

              }else if(
                (this.veryNextItem.routineType === 'exercise') || 
                (this.veryNextItem.routineType === 'exercise2') ||
                (this.veryNextItem.routineType === 'generic')
              ) {

                dataSetsArray.push(makeDataSet({
                    type: 'ColumnChart', 
                    series1Data: series1DataArray2, 
                    series2Data: series2DataArray2,
                    momentTitle: this.veryNextItem.momentTitle,
                    routineType: this.veryNextItem.routineType,

                    }))

              }


          
          }
          
          

          return dataSetsArray

        }else{
          return []
        }
        function getSum(total, num) {
          return total + Math.round(num);
        }
        function makeDataSet (payload) {
          //payload.type = "LineChart" or "ColumnChart"
          var lineChartOptions = {
            title: `Happiness related to '`+ payload.momentTitle+`' action`,
            titleTextStyle: {color: '#757575'},
            backgroundColor: {fill: '#000000',fillOpacity: 1.0},
            series: {0: { color: '#f0f0f0'},1: { color: '#ff0000'} },
            hAxis: {textStyle: {color: '#757575'} },
            vAxis: {textStyle: {color: '#575757'},gridlines: {color: "#575757"} },
            legend: {position: 'top', maxLines: 3, textStyle: {color: '#757575'} },
          }
          var chartOptions = {
            title: 'Total per day',
            titleTextStyle: {color: '#757575'},
            backgroundColor: {fill: '#000000',fillOpacity: 1.0},
            series: {0: { color: '#f0f0f0'},1: { color: '#ff0000'} },
            hAxis: {textStyle: {color: '#757575'} },
            vAxis: {textStyle: {color: '#575757'},gridlines: {color: "#575757"} },
            legend: {position: 'top', maxLines: 3, textStyle: {color: '#757575'} },
          }
          //payload.series1Data
          //payload.series2Data
          var chartDataArray = []
          chartDataArray.push(['Day of Week', 'Last week', 'This week'])
          var dayOfWeekArray = ['Mo','Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']
          dayOfWeekArray.forEach((letter, index) => { 
          //for(const[letter, index] in dayOfWeekArray) {
            var dataPointArray = []
            dataPointArray.push(letter)
            if((payload.routineType === 'exercise')) {
              dataPointArray.push(payload.series1Data[index].reduce(getSum, 0))
              dataPointArray.push(payload.series2Data[index].reduce(getSum, 0))
            }else {
              dataPointArray.push(payload.series1Data[index])
              dataPointArray.push(payload.series2Data[index])
            }
            chartDataArray.push(dataPointArray)
          })

          //next two lines prepare the data so that the dates are in order left to right
          //chartDataArray.reverse()
          //chartDataArray.unshift(['Day of Week', 'Last week', 'This week'])
          

          var options = (payload.type === 'LineChart') ? lineChartOptions : chartOptions
          var obj = {
            'chartData': chartDataArray,
            'chartOptions': options,
            'type': payload.type,
          }
          return obj
        }


      },


    },
    watch: {

    },
    methods: {
      //...mapActions('category', [
      //  'setCurrentCategory'
      //]),
      

    },
    beforeCreate () {
      //if(!this.$store.state.category) this.$store.registerModule('category', categoryModule)
    },
    created () {
      //this.fetchTheData();
      //this.searchLabelComputer();
    
    },
    mounted () {
      //this.$refs.searchBox.focus()
    },
    updated () {
      
    }
  }
</script>

<style scoped>
.wrapper {
  width: 100%;
  background-color: transparent;
}
a {
  color: inherit;
  text-decoration: none;
}


.cards {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(340px, 1fr));
  grid-gap: 2px;
  max-width: 700px;
  margin-bottom: 80px;
}

.item {
  background-color: transparent;
  padding: 10px;
  margin-bottom: 40px;
}




article {
  position: relative;
}
.article-img {
  height: 300px;
  width: 100%;
  object-fit: cover;
}
.article-title {
  font-family: 'Quicksand';
  font-weight: 900;
  position: absolute;
  bottom: 1px;
  width: 100%;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.65);
}


@media screen and (min-width: 900px) {
  
  .cards {
    grid-template-columns: repeat(auto-fill, minmax(440px, 1fr));
    grid-gap: 4px;
    max-width: 900px;
  }
}


@media screen and (min-width: 1300px) {
  
  .cards {
    grid-template-columns: 480px 480px;
    max-width: 1020px;
  }
}







</style>
<template>
  <div class="wrapper">
    <div class="card">
      <!--
      <div class="actionWakeButton">
        <router-link to="/routine/wake/">Action Wake</router-link>
      </div-->
      <div v-if="currentView=='INSERT_VIEW_HERE'">
        <div class="activeRoutineChips">
          <div v-if="userRoutines.length > 1">
            <v-chip
              class="mr-2 chip"
              v-for="(routine, index) in userRoutines" v-bind:key="'activeRoutineChip'+index"
              @click="handleChipClick(routine)"
              close
              @input="openDeleteDialog(routine)"
            >
            
              
              {{routine.routineTitle}}
            </v-chip>
          </div>
          <div v-else>
            <v-chip
              class="mr-2 chip"
              v-for="(routine, index) in userRoutines" v-bind:key="'activeRoutineChip'+index"
              @click="handleChipClick(routine)"
              
            >
            
              
              {{routine.routineTitle}}
            </v-chip>


          </div>
        </div>
        <div class="addRoutineIcon" @click="onClick">
          <router-link to="/routine/search" ><i class="fas fa-plus-circle"></i></router-link>
        </div>
        &nbsp;&nbsp;
      </div>
    </div>

    <!-- Delete Dialog -->
    <v-dialog
      v-model="deleteDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="headline">
          Confirm Delete
        </v-card-title>

        <v-card-text>
          You sure you want to remove this routine?
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            color="grey"
            text
            @click="deleteDialog = false"
          >
            Cancel
          </v-btn>

          <v-btn
            color="grey"
            text
            @click="removeRoutine"
          >
            Agree
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




    <!-- SNACKBAR -->
      <v-snackbar
        v-model="showRoutineDetailsSnackbar"
        color="info"
        multi-line
        :timeout=2000
        vertical
      >
      Routine Details are coming soon!
        <v-btn
          dark
          flat
          @click="showRoutineDetailsSnackbar = false"
        >
          Close
        </v-btn>
      </v-snackbar>
  </div>
</template>

<script>
  //Some example imports you could have:
    //import Worker from "worker-loader!@/worker";
    import routineModule from '@/store/routine'
    import { mapGetters, mapActions } from 'vuex'
    import '@/plugins/vuetify'
    //import MevChains from '@/components/MevChains'

  export default {
    name: "ActiveRoutines",
    components: {
      
    },
    props: {
      //minder: Object
    },
    data () {
      return {
        currentView: 'INSERT_VIEW_HERE',
        showRoutineDetailsSnackbar: false,
        deleteDialog: false,
        deleteRoutine: null,


      }
    },
    computed: {
      ...mapGetters('routine', [
       'userRoutines',
      ])
    },
    watch: {

    },
    methods: {
      ...mapActions('routine', [
        'getActiveRoutines',
        'deactivateUserRoutine',
      ]),
      onClick() {
        this.$emit('clear')
      },
      getRoutineDetail(routine) {
        this.$router.push('/routine/get/'+routine.routineType+'/'+routine.routineTemplateId)
      }, 
      async removeRoutine() {
        this.deleteDialog = false
        //await this.$emit('clear')
        //this.$nextTick(async () => {
          await this.deactivateUserRoutine(this.deleteRoutine)
          this.$nextTick(async () => {
            this.$emit('initialize-console')
          })
          
        //})
        
        
        /*
        this.$nextTick(async () => {
          var cb = await this.deactivateUserRoutine(this.deleteRoutine)
          console.log("userRoutines length: ", this.userRoutines.length)

          if((cb == "done") && (this.userRoutines.length > 0)) {
            console.log("emitting initialie console")
            this.$emit('initialize-console')
          }else{
            console.log("emitting clear")
            this.$emit('clear')
          }
        })
        */
        
      },


      handleChipClick(routine) {
        //showRoutineDetailsSnackbar = true
        this.$router.push('/routine/wake/'+routine.routineTemplateId)
      },

      openDeleteDialog(routine){
        this.deleteDialog = true
        this.deleteRoutine = routine
      }
      

    },
    beforeCreate () {
      if(!this.$store.state.routine) this.$store.registerModule('routine', routineModule)
    },
    created () {
      
      //this.searchLabelComputer();
    
    },
    mounted () {
      //var unsubscribe7 = firebase.auth().onAuthStateChanged(async (user) => { 
        //unsubscribe7()
        //if(user) {
            this.getActiveRoutines();
        //}
      //})
    },
    updated () {
      
    }
  }
</script>

<style scoped>
.wrapper {
  width: 100%;
}
a {
  color: inherit;
  text-decoration: none;
}
.card {
  position: relative;
  color: #8a8a8a;
  
}
.actionWakeButton {
  font-size: .65em;
  padding: 2px;
  padding-left: 8px;
  padding-right: 8px;
  position: absolute;
  top: 0;
  right: 0;
  border: solid 1px #8a8a8a;
}
.activeRoutineChips {
  display: inline-block;
  color: #551818;
  margin-left: 5px;
}
.addRoutineIcon {
  color: #808080;
  display: inline-block;
  margin-left: 70px;
  margin-top: 5px;
  font-size: 1.5em;
  transform: translateY(5px);

}

.chip {
  background-color: #222222;
  color: rgb(148, 148, 148);
}

@media screen and (min-width: 400px) {

  .activeRoutineChips {
    margin-left: 35px;
  }


}


</style>
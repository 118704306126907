<template>
  <div class="wrapper">
    <div class="card" @click="showCharts=!showCharts">
        

        <div class="routineIcon">
          <img :src="veryNextItem.routineIconImageGrey" class="routineIconImg">
        </div>
        <div v-for="(insight, index) in realInsights" v-bind:key="index" class="insightHolder">
          <transition name="fade">
            <div v-if="showInsight == index" class="insight" v-html="insight">
              {{ insight }}
            </div>
          </transition>
        </div>

        <!--
        <div ref="productBoxRef" class="productBox">
          <div class="products" width="70px"
            v-if="veryNextItem.momentTitle == 'do pushups'"
            v-html="products.pushupBars">
            {{ products.pushupBars }}
          </div>
          <div class="products"
            v-else-if="veryNextItem.momentTitle == 'drink water'"
            v-html="products.waterPurifier">
            {{ products.waterPurifier }}
          </div>
        </div>
        -->

        
        <!--
        <div v-if="happinessScores">
          <div v-if="happinessScores.length > 2" @click="insightExpanded=!insightExpanded" class="detailButton">
            <i class="fas fa-chevron-down" v-if="!insightExpanded"></i>
            <i class="fas fa-chevron-up" v-else></i>
          </div>
        </div>
        <div v-if="insightExpanded">
            <div v-if="happinessScores" class="sparkLineHolder">
              <br><br><br><br>
              <v-sparkline
                  v-if="happinessScores.length > 0"
                  :value="happinessScores"
                  :gradient="sparkline1.gradient"
                  :smooth="sparkline1.radius || false"
                  :padding="sparkline1.padding"
                  :line-width="sparkline1.width"
                  :stroke-linecap="sparkline1.lineCap"
                  :gradient-direction="sparkline1.gradientDirection"
                  :fill="sparkline1.fill"
                  :type="sparkline1.type"
                  :auto-line-width="sparkline1.autoLineWidth"
                  auto-draw
              ></v-sparkline>
            </div>
        </div>
        -->
    </div>
    <div class="detailButton">
      <i class="fas fa-chevron-down" v-if="!showCharts"></i>
      <i class="fas fa-chevron-up" v-else></i>
    </div>
    <div class="outcomeCharts" v-if="showCharts">
      
      <OutcomeCharts :outcomes-array="outcomesArray" :very-next-item="veryNextItem" />
    </div>
  </div>
</template>

<script>
  //Some example imports you could have:
    //import Worker from "worker-loader!@/worker";
    import insightModule from '@/store/insight'
    import { mapGetters, mapActions } from 'vuex'
    import OutcomeCharts from '@/components/OutcomeCharts'
    import moment from 'moment-timezone'
    const gradients = [
      ['#222'],
      ['#42b3f4'],
      ['red', 'orange', 'yellow'],
      ['purple', 'violet'],
      ['#999', '#444', '#333'],
      ['#f72047', '#ffd200', '#1feaea'],
      ['red','yellow','blue']
    ]
    //import '@/plugins/vuetify'
    //import MevChains from '@/components/MevChains'

  export default {
    name: "HeediInsighter",
    components: {
      OutcomeCharts,
    },
    props: {
      veryNextItem: {
        type: Object
      },
      parentCategory: {
        type: String
      },

      parentView: {
        type: String
      },
      routineType: {
        type: String
      },
      expiredItems: {
        type: Array
      }
    },
    data () {
      return {
        currentView: 'INSERT_VIEW_HERE',

        testInsights: [
          "This is the first insight",
          "Another really cool insight",
          "Finally, the last smart thing",
        ],


        showInsight: 0,
        showCharts: false,
        insightInterval: null,


        gotTheData: false,

        hitMissArray: [],

        agendaItems2: null,
        outcomes2: null,

        insightExpanded: false,

        sparkline1: {
          values: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
          width: 3,
          radius: 10,
          padding: 8,
          lineCap: 'round',
          gradient: gradients[5],
          gradientDirection: 'top',
          gradients,
          fill: false,
          type: 'trend',
          autoLineWidth: false,
        },




        products: {
          pushupBars: `<a target="_blank"  href="https://www.amazon.com/gp/product/B07GCXWN5G/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07GCXWN5G&linkCode=as2&tag=heedi0e-20&linkId=5b52f09297c8a440a30e6fad49e6e9dd"><img border="0" width="50%" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B07GCXWN5G&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL110_&tag=heedi0e-20" ></a>`,
          waterBottle: `<a target="_blank"  href="https://www.amazon.com/gp/product/B08B1VTTB9/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B08B1VTTB9&linkCode=as2&tag=heedi0e-20&linkId=f25d00c6bc0ac3a149dae307436e3815"><img border="0" width="50%" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B08B1VTTB9&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL110_&tag=heedi0e-20" ></a>`,
          waterPurifier: `<a target="_blank"  href="https://www.amazon.com/gp/product/B00VAG8M5I/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00VAG8M5I&linkCode=as2&tag=heedi0e-20&linkId=53ab2d03cfc2c7b73cae09e4da67026e"><img border="0" width="50%" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&MarketPlace=US&ASIN=B00VAG8M5I&ServiceVersion=20070822&ID=AsinImage&WS=1&Format=_SL110_&tag=heedi0e-20" ></a>`,
        },
        
        
      }
    },
    computed: {
      ...mapGetters('insight', [
        'routineTemplateAgendaItemLogs',
        'routineTemplateOutcomeLogs',
        'agendaItemLogs',
        'outcomeLogs',
        'happinessScores'

      ]),
      

      agendaItemArray () {
          if(this.agendaItemLogs) {
            var agendaItemKeys = Object.keys(this.agendaItemLogs)
            var items = this.agendaItemLogs
            var agendaItemArray = []
            agendaItemKeys.forEach((key) => {
              agendaItemArray.push(items[key])
            })
            return agendaItemArray
          }else {
            return null
          }
      },



      outcomesArray () {
        if(this.outcomeLogs) {
          var outcomeKeys = Object.keys(this.outcomeLogs)
          var outcomeArray = []
          outcomeKeys.forEach((key) => {
            outcomeArray.push(this.outcomeLogs[key])
          })
          return outcomeArray
        }else {
          return []
        }
      },

      outcomeValueTotals () {
        if(this.outcomeLogs) {
          var outcomeKeys = Object.keys(this.outcomeLogs)
          var outcomeValueTotals = []
          outcomeKeys.forEach((key) => {
            if( 
              (this.outcomeLogs[key].routineType === 'exercise')
            ) {

              
                var total = 0
                this.outcomeLogs[key].outcomes.forEach( out => {
                  if(out.stepType === 'action') {
                    total += out.stepValueA
                  }
                })
                outcomeValueTotals.push(total)
              

            }else if( 
              (this.outcomeLogs[key].routineType === 'exercise2') ||
              (this.outcomeLogs[key].routineType === 'generic')
            ) {
              if (this.veryNextItem.momentSteps) {
                
                var total2 = 0
                this.outcomeLogs[key].outcomes.forEach( out => {
                  if (this.veryNextItem.momentSteps[0].stepActionId === out.stepActionId) {
                    if(out.stepType === 'action') {
                      total2 += out.stepValueA
                    }
                  }
                })
                outcomeValueTotals.push(total2)
                
              }

                
            }else if (
              (this.outcomeLogs[key].routineType === 'cessation') ||
              (this.outcomeLogs[key].routineType === 'everyday habit') ||
              (this.outcomeLogs[key].routineType === 'work sessions') ||
              (this.outcomeLogs[key].routineType === 'cooking')
            ) {


              outcomeValueTotals.push(this.outcomeLogs[key].outcomeActionStepValueTotal)
            
            
            }else if (
              (this.outcomeLogs[key].routineType === 'event follow-up') 
            ) {


              outcomeValueTotals.push(this.outcomeLogs[key].outcomeValue )
            
            
            }
          })




          return outcomeValueTotals
        }else{
          return []
        }
      },

      outcomesGrandTotal () {
        function getSum(total, num) {
          return total + Math.round(num);
        }
        if(this.outcomeValueTotals) {
          var grandTotalOutcomes = this.outcomeValueTotals.reduce(getSum, 0)
          return grandTotalOutcomes
        }else{
          return null
        }
      },


      thisWeekEndTime () {
        //console.log("thisWeekEndTime",moment().endOf('isoWeek').format())
        return moment().endOf('isoWeek').valueOf()
      },
      thisWeekStartTime () {
        //console.log("thisWeekStartTime", moment().startOf('isoWeek').format())
        return moment().startOf('isoWeek').valueOf()
      },
      lastWeekStartTime () {
        //console.log("lastWeekStartTime", moment().startOf('isoWeek').subtract(1, 'weeks').format())
        return moment().startOf('isoWeek').subtract(1, 'weeks').valueOf()
      },
      lastWeekEndTime () { 
        //console.log("lastWeekEndTime", moment().endOf('isoWeek').subtract(1, 'weeks').format())
        return moment().endOf('isoWeek').subtract(1, 'weeks').valueOf()
      },
      nowTime () { 
        return moment().valueOf()
      },
      startOfDayTime () {
        return moment().startOf('day').valueOf()
      },
      yesterdayStartOfDayTime () {
        return moment().subtract(1,'day').startOf('days').valueOf()
      },
      yesterdayEndOfDayTime () {
        return moment().subtract(1,'day').endOf('days').valueOf()
      },


      hitMissLine () {
        if(this.agendaItemArray && this.outcomeLogs && this.outcomeLogs != {}) {
          //last 10 hit/miss
          var limit = 7
          var i = 0
          var hitMissLine = null
          var upToNowAgendaItems = this.agendaItemArray.filter(item => {
            return (item.startTime < this.nowTime)   // Items with startTime's in future that were completed 
          })
          upToNowAgendaItems.forEach(item => {
            if(i < limit) {
              if(i == 0) {
                hitMissLine = ` <span style='color: #333'>|</span> `
              }
              if(this.outcomeLogs[item.agendaItemId]){
                
                hitMissLine += `+ <span style='color: #333'>|</span> `
              }else{
                hitMissLine += `- <span style='color: #333'>|</span> `
              }
            }
            ++i
          })
          if(hitMissLine == ` <span style='color: #333'>|</span> `) {
            return null
          }else {
            return hitMissLine
          }
          
        }else {
          return null
        }
      },

      thisWeekAgendaItems () {
        if(this.agendaItemArray) {
          var thisWeekItems = this.agendaItemArray.filter((item) => {
            return (this.thisWeekStartTime < item.startTime) && (item.startTime < this.thisWeekEndTime)
          })
          return thisWeekItems
        }else{
          return null
        }
      },

      thisWeekUpToNowAgendaItems () {
        if(this.agendaItemArray) {
          var val = this.agendaItemArray.filter((item) => {
            return (this.thisWeekStartTime < item.startTime) && (item.startTime < this.nowTime)
          })
          return val
        }else{
          return null
        }
      },


      thisWeekOutcomes () {
        if(this.outcomesArray) {
          var thisWeekOutcomes = this.outcomesArray.filter((item) => {
            return (this.thisWeekStartTime < item.momentStartTime) && (item.momentStartTime < this.thisWeekEndTime)
          })
          return thisWeekOutcomes
        }else{
          return null
        }
      },

      thisWeekOutcomeValueTotal () {
        if(this.thisWeekOutcomes) {
          //total for this week
          var thisWeekOutcomeValueTotals = []
          this.thisWeekOutcomes.forEach((outcome) => {

           if( 
              (outcome.routineType === 'exercise') 
            ) {
              
                var total = 0
                outcome.outcomes.forEach( out => {
                  if(out.stepType === 'action') {
                    total += out.stepValueA
                  }
                })
                thisWeekOutcomeValueTotals.push(total)
              
            }else if( 
              (outcome.routineType === 'exercise2') ||
              (outcome.routineType === 'generic')
            ) {
              if (this.veryNextItem.momentSteps) {
                var total3 = 0
                outcome.outcomes.forEach( out => {
                  if(this.veryNextItem.momentSteps[0].stepActionId === out.stepActionId) {
                    if(out.stepType === 'action') {
                      total3 += out.stepValueA
                    }
                  }
                })
                thisWeekOutcomeValueTotals.push(total3)
                
              }
              
            }else if (
              (outcome.routineType === 'cessation') ||
              (outcome.routineType === 'everyday habit') ||
              (outcome.routineType === 'work sessions') ||
              (outcome.routineType === 'cooking')
            ) {

              thisWeekOutcomeValueTotals.push(outcome.outcomeActionStepValueTotal)
            
            }else if (
              (outcome.routineType === 'event follow-up') 
            ) {

              thisWeekOutcomeValueTotals.push(outcome.outcomeValue )
          
            }
          })


          
          var thisWeekOutcomeValueTotal = thisWeekOutcomeValueTotals.reduce(getSum, 0)
          return thisWeekOutcomeValueTotal
        }else{
          return null
        }
        function getSum(total, num) {
            return total + Math.round(num);
          }
      },


      thisWeekDidItRate () {
        if(this.thisWeekUpToNowAgendaItems) {
          //total hits
          var thisWeekOutcomeDidItHits = 0
          this.thisWeekUpToNowAgendaItems.forEach(item => {
            if(item.isDone) {
              ++thisWeekOutcomeDidItHits
            }
          })
          var thisWeekOutcomeOpps = this.thisWeekUpToNowAgendaItems.length  
          var thisWeekDidItRate = (thisWeekOutcomeOpps > 0) ? thisWeekOutcomeDidItHits / thisWeekOutcomeOpps : 0
          return Math.round(thisWeekDidItRate * 100)+"%"
        }else{
          return null
        }        
      },


      lastWeekAgendaItems () {
        if(this.agendaItemArray) {
          var lastWeekItems = this.agendaItemArray.filter((item) => {
            return (this.lastWeekStartTime < item.startTime) && (item.startTime < this.lastWeekEndTime)
          })
          return lastWeekItems
        }else{
          return null
        }
      },



      lastWeekOutcomes () {
        if(this.outcomesArray) {
          var lastWeekOutcomes = this.outcomesArray.filter((item) => {
            return (this.lastWeekStartTime < item.momentStartTime) && (item.momentStartTime < this.lastWeekEndTime)
          })
          return lastWeekOutcomes
        }else{
          return null
        }
      },


      lastWeekOutcomeValueTotal () {
        if(this.lastWeekOutcomes) {
          //total for this week
          var lastWeekOutcomeValueTotals = []
          this.lastWeekOutcomes.forEach(outcome => {

            if( 
              (outcome.routineType === 'exercise') 
            ) {
              
                var total = 0
                outcome.outcomes.forEach( out => {
                  if(out.stepType === 'action') {
                    total += out.stepValueA
                  }
                  
                })
                lastWeekOutcomeValueTotals.push(total)
              
            }else if( 
              (outcome.routineType === 'exercise2') ||
              (outcome.routineType === 'generic')
            ) {
              if (this.veryNextItem.momentSteps) {
                var total4 = 0
                outcome.outcomes.forEach( out => {
                  if(this.veryNextItem.momentSteps[0].stepActionId === out.stepActionId) {
                    if(out.stepType === 'action') {
                      total4 += out.stepValueA
                    }
                  }
                })
                lastWeekOutcomeValueTotals.push(total4)
                
              }

            }else if (
              (outcome.routineType === 'cessation') ||
              (outcome.routineType === 'everyday habit') ||
              (outcome.routineType === 'work sessions') ||
              (outcome.routineType === 'cooking')
            ) {

              lastWeekOutcomeValueTotals.push(outcome.outcomeActionStepValueTotal)
            
            }else if (
              (outcome.routineType === 'event follow-up') 
            ) {

              lastWeekOutcomeValueTotals.push(outcome.outcomeValue )
          
            }
          })

          
          var lastWeekOutcomeValueTotal = lastWeekOutcomeValueTotals.reduce(getSum, 0)
          return lastWeekOutcomeValueTotal
        }else{
          return null
        }
        function getSum(total, num) {
            return total + Math.round(num);
          }
      }, 


      lastWeekDidItRate () {
        if(this.lastWeekAgendaItems) {
          //total hits
          var lastWeekOutcomeDidItHits = 0
          this.lastWeekAgendaItems.forEach(item => {
            if(item.isDone) {
              ++lastWeekOutcomeDidItHits
            }
          })
          var lastWeekOutcomeOpps = this.lastWeekAgendaItems.length  
          var lastWeekDidItRate = (lastWeekOutcomeOpps > 0) ? lastWeekOutcomeDidItHits / lastWeekOutcomeOpps : 0
          return Math.round(lastWeekDidItRate * 100)+"%"
        }else{
          return null
        } 
      },



      todayAgendaItems () {
        if(this.agendaItemArray) {
          var todayAgendaItems = this.agendaItemArray.filter((item) => {
            return this.startOfDayTime < item.startTime
          })
          return todayAgendaItems
        }else{
          return null
        }
      },


      todayOutcomes () {
        if(this.outcomesArray){
          var todayOutcomes = this.outcomesArray.filter((item) => {
            return this.startOfDayTime < item.momentStartTime
          })
          return todayOutcomes
        }else {
          return null
        }

      },

      todayOutcomeValueTotal () {
        if(this.todayOutcomes){
          var todayOutcomeValueTotals = []

          this.todayOutcomes.forEach(outcome => {

            if( 
              (outcome.routineType === 'exercise') 
            ) {
              
                var total5 = 0
                outcome.outcomes.forEach( out => {
                  if(out.stepType === 'action') {
                    total5 += out.stepValueA
                  }
                })
                todayOutcomeValueTotals.push(total5)
              
            }else if( 
              (outcome.routineType === 'exercise2') ||
              (outcome.routineType === 'generic')
            ) {
              if (this.veryNextItem.momentSteps) {
                var total6 = 0
                outcome.outcomes.forEach( out => {
                  if(this.veryNextItem.momentSteps[0].stepActionId === out.stepActionId) {
                    if(out.stepType === 'action') {
                      total6 += out.stepValueA
                    }
                  }
                })
                todayOutcomeValueTotals.push(total6)
                
              }

            }else if (
              (outcome.routineType === 'cessation') ||
              (outcome.routineType === 'everyday habit') ||
              (outcome.routineType === 'work sessions') ||
              (outcome.routineType === 'cooking')
            ) {

              todayOutcomeValueTotals.push(outcome.outcomeActionStepValueTotal)
            
            }else if (
              (outcome.routineType === 'event follow-up') 
            ) {

              todayOutcomeValueTotals.push(outcome.outcomeValue )
          
            }
          
            //todayOutcomeValueTotals.push(outcome.outcomeActionStepValueTotal)

          })


          
          var todayOutcomeValueTotal = todayOutcomeValueTotals.reduce(getSum, 0)
          return todayOutcomeValueTotal
        }else{
          return null
        }
        function getSum(total, num) {
            return total + Math.round(num);
          }
      },



      todayDidItRate () {
        if(this.todayAgendaItems) {
          var todayOutcomeDidItHits = 0
          this.todayAgendaItems.forEach(item => {
            if(item.isDone) {
              ++todayOutcomeDidItHits
            }
          })
          //total opportunities
          var todayOutcomeOpps = this.todayAgendaItems.length  
          //didItRate = hits/opportunities
          var todayDidItRate = (todayOutcomeOpps > 0) ? todayOutcomeDidItHits / todayOutcomeOpps : 0
          return todayDidItRate
        }else {
          return null
        }
      },



      yesterdayAgendaItems () {
        if(this.agendaItemArray) {
          var yesterdayAgendaItems = this.agendaItemArray.filter((item) => {
            return (this.yesterdayStartOfDayTime < item.startTime) && (item.startTime < this.yesterdayEndOfDayTime)
          })
          return yesterdayAgendaItems
        }else{
          return null
        }
      },


      yesterdayOutcomes () {
        if(this.outcomesArray) {
          var yesterdayOutcomes = this.outcomesArray.filter((item) => {
            return (this.yesterdayStartOfDayTime < item.startTime) && (item.startTime < this.yesterdayEndOfDayTime)
          })
          return yesterdayOutcomes
        }else{
          return null
        }
      },

      yesterdayOutcomeValueTotal () {
        if(this.yesterdayOutcomes){
          var yesterdayOutcomeValueTotals = []
          this.yesterdayOutcomes.forEach(outcome => {

            if( 
              (outcome.routineType === 'exercise') 
            ) {
              
                var total7 = 0
                outcome.outcomes.forEach( out => {
                  if(out.stepType === 'action') {
                    total7 += out.stepValueA
                  }
                })
                yesterdayOutcomeValueTotals.push(total7)
              
            }else if( 
              (outcome.routineType === 'exercise2') ||
              (outcome.routineType === 'generic')
            ) {
              if (this.veryNextItem.momentSteps) {
                
                var total8 = 0
                outcome.outcomes.forEach( out => {
                  if(this.veryNextItem.momentSteps[0].stepActionId === out.stepActionId) {
                    if(out.stepType === 'action') {
                      total8 += out.stepValueA
                    }
                  }
                })
                yesterdayOutcomeValueTotals.push(total8)
                
              }

            }else if (
              (outcome.routineType === 'cessation') ||
              (outcome.routineType === 'everyday habit') ||
              (outcome.routineType === 'work sessions') ||
              (outcome.routineType === 'cooking')
            ) {

              yesterdayOutcomeValueTotals.push(outcome.outcomeActionStepValueTotal)
            
            }else if (
              (outcome.routineType === 'event follow-up') 
            ) {

              yesterdayOutcomeValueTotals.push(outcome.outcomeValue )
          
            }
          
          })
 
          var yesterdayOutcomeValueTotal = yesterdayOutcomeValueTotals.reduce(getSum, 0)
          return yesterdayOutcomeValueTotal
        }else{
          return null
        }
        function getSum(total, num) {
            return total + Math.round(num);
          }

      },

      yesterdayDidItRate () {
        if(this.yesterdayAgendaItems) {
          var yesterdayOutcomeDidItHits = 0
          this.yesterdayAgendaItems.forEach(item => {
            if(item.isDone) {
              ++yesterdayOutcomeDidItHits
            }
          })
          //total opportunities
          var yesterdayOutcomeOpps = this.yesterdayAgendaItems.length  
          //didItRate = hits/opportunities
          var yesterdayDidItRate = (yesterdayOutcomeOpps > 0) ? yesterdayOutcomeDidItHits / yesterdayOutcomeOpps : 0
          return yesterdayDidItRate
        }else {
          return null
        }
      },



      streak () {
        if(this.agendaItemArray) {
          var upToNowAgendaItems = this.agendaItemArray.filter(item => {
            return (item.startTime < this.nowTime) 
          })
          var sortedUpToNowAgendaItems = upToNowAgendaItems.sort((a, b) => parseFloat(b.startTime) - parseFloat(a.startTime));
          var streak = 0;
          for(var i = 0; i < sortedUpToNowAgendaItems.length; i++) {
            if(sortedUpToNowAgendaItems[i].isDone) {   //If an agendaItem is in the ready state, it will be past nowTime, and will also not be done, so the streak will be zero
              ++streak
            }else{
              return streak
            }
          }
          return streak
        }else{
          return null
        }
      },



      realInsights () {
        if(this.agendaItemArray) {
          var insights = []
          if(this.todayOutcomeValueTotal) {
            insights.push("Today you have done " + this.todayOutcomeValueTotal )
          }
          if(this.yesterdayOutcomeValueTotal) {
            insights.push("Yesterday you did " + this.yesterdayOutcomeValueTotal)
          }
          if(this.lastWeekOutcomeValueTotal) { 
            insights.push("Last week you did "+ this.lastWeekOutcomeValueTotal )
          } 
          if(this.thisWeekOutcomeValueTotal) {
            insights.push("This week you have done "+ this.thisWeekOutcomeValueTotal)
          }
          if(this.thisWeekDidItRate) {
            insights.push("Your Did It rate this week is "+ this.thisWeekDidItRate)
          }
          if(this.streak > -1) { 
            insights.push("Your current streak is "+ this.streak) }
          if(this.yesterdayOutcomeValueTotal > this.todayOutcomeValueTotal) {
            insights.push("You are "+ (this.yesterdayOutcomeValueTotal - this.todayOutcomeValueTotal) + " away from matching yesterday's total of " + this.yesterdayOutcomeValueTotal)
          }
          if(this.hitMissLine && (this.streak < 7)) {      //After the streak hits ten, this loses usability
            insights.push("Recent hits or misses:<br>&nbsp;&nbsp;" + this.hitMissLine )
          }

          //Partner insights
            //Compare today totals
            //Compare week totals
            //Determine who is having a better week
            //Determine what the other needs to do to catch up
            //


          //Group Insights
            //Baseline data






          return insights
        }else{
          return []
        }
        
      },




      
    },
    watch: {

      veryNextItem: {
        deep: true,
        immediate: true,
        handler: async function (newVal) {
          if(newVal) {         //This getTheData pattern skips the first iteration and process the rest

              //if(newVal != oldVal) {
                // console.log("newVal for veryNextItem received")
                await this.getOutcomeData({'routineTemplateId': newVal.routineTemplateId})
                await this.getAgendaItemData({'routineTemplateId': newVal.routineTemplateId})
                await this.getHappinessScoresData({'routineTemplateId': newVal.routineTemplateId})

                this.evaluateProductBox()

              //}
          }
        }
      },

    },
    methods: {
      ...mapActions('insight', [
        'getSupportingData',
        'getOutcomeData',
        'getAgendaItemData',
        'unsubscribeToData',
        'getHappinessScoresData'
      ]),

      expandDetail () {
        this.insightExpanded = true
      }


      

    },
    beforeCreate () {
      if(!this.$store.state.insight) this.$store.registerModule('insight', insightModule)
    },
    async created () {
      this.agendaItems2 = await this.getAgendaItemData({'routineTemplateId': this.veryNextItem.routineTemplateId})
      this.outcomes2 = await this.getOutcomeData({'routineTemplateId': this.veryNextItem.routineTemplateId})
    
    },
    async mounted () {

      
      
      
      //start the 
      this.showInsight = 0
      this.insightInterval = setInterval(async () => {
        var len = this.realInsights.length
        var nextIndex = this.showInsight + 1
        if(nextIndex == len) {   //at the end
          this.showInsight = 0
        }else if(nextIndex < len) {
          ++this.showInsight
        }
      }, 10000)



      


    },
    updated () {
      
    }
  }
</script>

<style scoped>
.wrapper {
  width: 100%;
  position: relative;
}
a {
  color: inherit;
  text-decoration: none;

}
.card {
  font-family: 'Quicksand', sans-serif;
  font-weight: 400;
  position: relative;
  width: 100%;
  max-width: 1200px;
  min-height: 160px;
  color: rgb(144, 144, 144);
  margin: 0 auto;
  background-color: transparent;
  margin-top: 5px;
  
}

.intentMeanCategory {
  position: absolute;
  font-family: 'Quicksand', sans-serif;
  font-weight: 600;
  top: 0;
  left: 50%;
  transform: translateY(-50%);
  font-size: .75em;
}

.outcomeCharts {
  margin: 0 auto;
  background-color: transparent;
  max-width: 1200px;
}

.fade-enter-active, .fade-leave-active {
  transition: all 1.5s ease-in-out;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateX(-1%);
}

.insight {
  position: absolute;
  top: 40px;
  left: 74px;
  z-index: 3;
  width: 200px;
  font-size: 1.0em;
  color: #8a8a8a;
}
.routineIconImg {
  width: 60px;
  opacity: .5;
}
.routineIcon {
  position: absolute;
  top: 50px;
  left: -2px;
  padding-left: 10px;
  padding-right: 10px;
}

.products {
  position: absolute;
  bottom: -60px;
  right: 10px;
  padding: 4px;
  text-align: center;
  z-index: 100;
  background-color: white;
}

.productBox {
  background-color: green;
}




.detailButton {
  color: #5e5e5e;
  position: absolute;
  display: inline-block;
  background-color: transparent;
  top: 32px;
  right: 16px;

}

.sparkLineHolder {
  width: 300px;
}




@media screen and (min-width: 360px) {
  .insight {
    top: 30px;
    left: 78px;
    width: 235px;
    font-size: 1em;
    background-color: transparent;
  }
  .routineIcon {
    top: 40px;
    left: 0;
  }
  
}

@media screen and (min-width: 375px) {
  .routineIcon {
    left: 5px;
  }
  .insight {
    width: 245px;
    font-size: 1.05em;
    left: 90px;
  }
}


@media screen and (min-width: 410px) {
  .insight {
    width: 255px;
    left: 95px;
    top: 30px;
    font-size: 1.05em;
  }
  .routineIcon {
    top: 40px;
    left: 8px;
  }
  .card {
    min-height: 160px;
  }
  
}

@media screen and (min-width: 500px) {
  .insight {
    width: 330px;
  }

  
}

@media screen and (min-width: 768px) {
  .insight {
    width: 380px;
    left: 120px;
    font-size: 1.5em;
  }

  .routineIcon {
    left: 20px;
  }

  
}


@media screen and (min-width: 800px) {
  .insight {
    width: 700px;
    font-size: 1.8em;
    top: 50px;
    left: 95px;
    background-color: transparent;
  }
  .routineIcon {
    top: 60px;
    left: 10px;
  }
  .card {
    min-height: 170px;
  }
}


@media screen and (min-width: 900px) {
  .outcomeCharts {
    max-width: 900px;
  }

  
}

@media screen and (min-width: 1000px) {
  .insight {
    width: 750px;
    font-size: 1.8em;
    top: 50px;
    left: 125px;
    background-color: transparent;
  }
  .routineIcon {
    top: 60px;
    left: 30px;
  }
  .card {
    min-height: 180px;
  }

  .detailButton {
    right: 75px;
  }
}

@media screen and (min-width: 1100px) {
  .detailButton {
    right: 75px;
  }
}

@media screen and (min-width: 1200px) {
  .insight {
    width: 900px;
    font-size: 1.9em;
    top: 45px;
    left: 125px;
  }
  .routineIcon {
    top: 50px;
    left: 20px;
  }
  .routineIconImg {
    width: 70px;
  }
}


</style>